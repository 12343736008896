import React, { useEffect } from "react";
import axios from "axios";
import { Card, H2, H3, IToaster, HTMLTable, Icon } from "@blueprintjs/core"
import { Col, Row } from "react-grid-system"

interface IProps {
    id: string
    toaster: IToaster
    ea: boolean | undefined
    setEA: Function
    setPrevs: Function
    prevs: boolean | undefined
    noGens: number | undefined
    setNoGens: Function
    popSize: number | undefined
    setPopSize: Function
}

function ReceptorInfo(props: {filename: string, FASTA: string, mirrored: boolean}) {
    return <HTMLTable striped bordered>
        <tbody>
            <tr>
                <td>Filename</td>
                <td>{props.filename}</td>
            </tr>
            <tr>
                <td>FASTA</td>
                <td style={{wordWrap: "break-word", maxWidth: "200px"}}>{props.FASTA}</td>
            </tr>
            <tr>
                <td>Mirrored?</td>
                <td>{props.mirrored ? <Icon icon="tick"></Icon>: <Icon icon="cross"></Icon>}</td>
            </tr>
        </tbody>
    </HTMLTable>
}

function EAParams(props: {noGens: Number | undefined, popSize: Number | undefined, probMut: Number,
                          copyRate: Number, exhaustiveness: Number, elitism: Number,
                          prevs: boolean | undefined}) {

    return <HTMLTable striped bordered>
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Number of generations</td>
                    <td>{props.noGens}</td>
                </tr>
                <tr>
                    <td>Population size</td>
                    <td>{props.popSize}</td>
                </tr>
                <tr>
                    <td>Probability of mutation</td>
                    <td>{props.probMut}</td>
                </tr>
                <tr>
                    <td>Copy rate</td>
                    <td>{props.copyRate}</td>
                </tr>
                <tr>
                    <td>Exhaustiveness</td>
                    <td>{props.exhaustiveness}</td>
                </tr>
                <tr>
                    <td>Elitism</td>
                    <td>{props.elitism}</td>
                </tr>
            </tbody>
        </HTMLTable>
}
/*
<tr>
    <td>Pre-Virtual Screening</td>
    <td>{props.prevs ? <Icon icon="tick"></Icon>: <Icon icon="cross"></Icon>}</td>
</tr>
*/

function VSParams(props: {exhaustiveness: Number}) {

    return <HTMLTable striped bordered>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Exhaustiveness</td>
                        <td>{props.exhaustiveness}</td>
                    </tr>
                </tbody>
    </HTMLTable>
}

export default function SubmissionParams(props: IProps) {
    const [probMut, setProbMut] = React.useState<Number>(0);
    const [copyRate, setCopyRate] = React.useState<Number>(0);
    const [elitism, setElitism] = React.useState<Number>(0);
    const [exhaustiveness, setExhaustiveness] = React.useState<Number>(0);
    const [mirrored, setMirrored] = React.useState<boolean>(false);
    const [filename, setFilename] = React.useState<string>("");
    const [FASTA, setFASTA] = React.useState<string>("");

    useEffect(() => {
        axios.get("/jobinfo/type", {
            params: {
                id: props.id,
            }
        }).then((response1) => {
            axios.get("/jobinfo/params", {
                params: {
                    id: props.id
                }
            }).then((response) => {
                if (response1.data.ea) {
                    props.setNoGens(response.data.ngens);
                    props.setPopSize(response.data.popsize);
                    setProbMut(response.data.mutateprob);
                    setCopyRate(response.data.copyrate);
                    setExhaustiveness(response.data.exhaustiveness);
                    setElitism(response.data.elitism);
                    setMirrored(response.data.mirror);
                    props.setEA(true);
                    props.setPrevs(response.data.prevs);
                } else {
                    setExhaustiveness(response.data.exhaustiveness);
                    setMirrored(response.data.mirror);
                    props.setEA(false);
                }
                axios.get("/jobinfo/receptor", {
                    params: {
                        id: props.id
                    }
                }).then((response) => {
                    setFilename(response.data.name);
                    setFASTA(response.data.FASTA);
                })
            }).catch(
                (error) => {
                    props.setEA(undefined);
                    props.toaster.show({message: "Error in params fetching: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                }
            )
        }).catch(
            (error) => {
                props.setEA(undefined);
                props.toaster.show({message: "Error in type fetching: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }, [])

    return(   
        <Card elevation={1}>
            <Row>
                <Col md={12}>
                    <H2>{props.ea === undefined ? null : (props.ea === false ? "Virtual Screening" : "Evolutionary Algorithm")}: {props.id}</H2>
                </Col>
                <Col md={6}>
                    <H3>Parameters:</H3>
                    {props.ea === undefined ? null :
                    (props.ea === false 
                    ? <VSParams exhaustiveness={exhaustiveness}/>
                    : <EAParams exhaustiveness={exhaustiveness} noGens={props.noGens} popSize={props.popSize} probMut={probMut} copyRate={copyRate}
                                elitism={elitism} prevs={props.prevs}/>)}
                </Col>
                <Col md={6}>
                    <H3>Receptor:</H3>
                    <ReceptorInfo mirrored={mirrored} filename={filename} FASTA={FASTA}/>
                </Col>
            </Row>
        </Card>)
}