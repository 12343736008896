import React from "react";
import { Container, Row, Col } from "react-grid-system"
import { Link } from "react-router-dom";
import KREUZ from "./assets/kreuz_v3.png";
import { Callout } from "@blueprintjs/core";

export default function Home() {
    return (
        <Container className="Home">
	{ /*
		Remove curly braces and comment symbols to show technical issues message
	<Callout title="Technical Issues" intent="danger">
	finDr is currently facing technical issues. Please do not submit a job until this message disappears, as we cannot guarantee that it will finish.
	</Callout>
	 */ } 

            <Row>
                <Col md={8}>
                    
                    <Row>
                        <Col xl={7}>
                        <h1>Welcome to finDr!</h1>
                            <p className="bp3-running-text bp3-text-large"> finDr is a web server for the identification of metabolically stable D-peptide ligands to any target protein of choice. For de novo D-peptide ligand identification you can perform mirror-image virtual screening (MIVS) of a helical peptide library or you can use the mirror-image evolutionary algorithm (MIEA). We suggest a combination of the two – performing MIVS and further optimizing the top hits of this screening by a custom-tailored MIEA. </p>
                            <p className="bp3-running-text bp3-text-large"> For more information on the biology and bioinformatics behind finDr, take a look at the <Link to="/principle">Principle</Link> and <Link to="/example">Example</Link> pages. </p>
                            <p className="bp3-running-text bp3-text-large"> We hope that finDr will help you in your research! If you use finDr for a scientific publication please cite. </p>
                        </Col>
                        <Col xl={5}>
                            <div style={{height: "1px", width: "100%", maxWidth: "350px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "10px", marginBottom: "5px"}}></div>
                            <div className="ImgWithCaption">
                                <img src={KREUZ} alt="Mirror-image principle" style={{width: "100%", maxWidth: "350px"}}/>
                                <p style={{paddingTop: "15px"}}>D-peptide ligand design by using mirror image principle</p>
                            </div>
                            <div style={{height: "1px", width: "100%", maxWidth: "350px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "10px"}}></div>
                        </Col>
                    </Row>
                    
                 
                </Col>
                <Col md={4}>
                    <h3>Citation</h3>
                    <h4>finDr: A web server for in silico D-peptide ligand identification</h4>
                    <p>Helena Engel, Felix Guischard, Fabian Krause, Janina Nandy, Paulina Kaas, Nico Höfflin, Maja Köhn, Normann Kilb, Karsten Voigt, Steffen Wolf, Tahira Aslan, Fabian Baezner, Salomé Hahne, Carolin Ruckes, Joshua Weygant, Alisa Zinina, Emir Bora Akmeriç, Enoch B. Antwi, Dennis Dombrovskij, Philipp Franke, Klara L. Lesch, Niklas Vesper, Daniel Weis, Nicole Gensch, Barbara Di Ventura, Mehmet Ali Öztürk</p>
                    <p><i>Synthetic and Systems Biotechnology, Volume 6, Issue 4, 2021</i></p>
                    <a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.1016/j.synbio.2021.11.004">https://doi.org/10.1016/j.synbio.2021.11.004</a>
                </Col>
            </Row>
        </Container>
    )
}
