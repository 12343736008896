const dummypdb: Blob = new Blob([`
ATOM      1  N   ARG     1      -7.348   2.216   5.405  1.00  0.00           N  
ATOM      2  CA  ARG     1      -6.807   2.895   4.231  1.00  0.00           C  
ATOM      3  C   ARG     1      -5.297   2.912   4.265  1.00  0.00           C  
ATOM      4  O   ARG     1      -4.620   2.607   3.285  1.00  0.00           O  
ATOM      5  CB  ARG     1      -7.389   4.336   4.173  1.00  0.00           C  
ATOM      6  CG  ARG     1      -7.138   5.106   2.846  1.00  0.00           C  
ATOM      7  CD  ARG     1      -7.714   6.531   2.887  1.00  0.00           C  
ATOM      8  NE  ARG     1      -7.462   7.212   1.583  1.00  0.00           N  
ATOM      9  CZ  ARG     1      -7.823   8.456   1.283  1.00  0.00           C  
ATOM     10  NH1 ARG     1      -8.447   9.246   2.111  1.00  0.00           N1+
ATOM     11  NH2 ARG     1      -7.541   8.911   0.103  1.00  0.00           N  
ATOM     12  H   ARG     1      -6.742   1.818   6.131  1.00  0.00           H  
ATOM     13  HA  ARG     1      -7.115   2.325   3.334  1.00  0.00           H  
ATOM     14 2HB  ARG     1      -6.990   4.916   5.031  1.00  0.00           H  
ATOM     15 3HB  ARG     1      -8.482   4.294   4.356  1.00  0.00           H  
ATOM     16 2HG  ARG     1      -7.578   4.533   2.004  1.00  0.00           H  
ATOM     17 3HG  ARG     1      -6.050   5.157   2.636  1.00  0.00           H  
ATOM     18 2HD  ARG     1      -7.246   7.093   3.725  1.00  0.00           H  
ATOM     19 3HD  ARG     1      -8.802   6.485   3.108  1.00  0.00           H  
ATOM     20  HE  ARG     1      -6.978   6.728   0.820  1.00  0.00           H  
ATOM     21 1HH1 ARG     1      -8.638   8.827   3.024  1.00  0.00           H  
ATOM     22 2HH1 ARG     1      -8.691  10.189   1.804  1.00  0.00           H  
ATOM     23 1HH2 ARG     1      -7.051   8.278  -0.532  1.00  0.00           H  
ATOM     24 2HH2 ARG     1      -7.828   9.868  -0.109  1.00  0.00           H  
ATOM     25  N   ARG     2      -4.700   3.257   5.355  1.00  0.00           N  
ATOM     26  CA  ARG     2      -3.241   3.273   5.387  1.00  0.00           C  
ATOM     27  C   ARG     2      -2.677   1.920   5.021  1.00  0.00           C  
ATOM     28  O   ARG     2      -1.761   1.794   4.212  1.00  0.00           O  
ATOM     29  CB  ARG     2      -2.776   3.716   6.804  1.00  0.00           C  
ATOM     30  CG  ARG     2      -1.264   4.053   6.937  1.00  0.00           C  
ATOM     31  CD  ARG     2      -0.884   4.446   8.374  1.00  0.00           C  
ATOM     32  NE  ARG     2       0.570   4.778   8.436  1.00  0.00           N  
ATOM     33  CZ  ARG     2       1.227   5.150   9.530  1.00  0.00           C  
ATOM     34  NH1 ARG     2       0.670   5.267  10.703  1.00  0.00           N1+
ATOM     35  NH2 ARG     2       2.492   5.414   9.428  1.00  0.00           N  
ATOM     36  H   ARG     2      -5.234   3.511   6.173  1.00  0.00           H  
ATOM     37  HA  ARG     2      -2.890   3.998   4.629  1.00  0.00           H  
ATOM     38 2HB  ARG     2      -3.056   2.927   7.533  1.00  0.00           H  
ATOM     39 3HB  ARG     2      -3.365   4.601   7.119  1.00  0.00           H  
ATOM     40 2HG  ARG     2      -1.010   4.870   6.231  1.00  0.00           H  
ATOM     41 3HG  ARG     2      -0.651   3.184   6.621  1.00  0.00           H  
ATOM     42 2HD  ARG     2      -1.129   3.607   9.062  1.00  0.00           H  
ATOM     43 3HD  ARG     2      -1.503   5.310   8.698  1.00  0.00           H  
ATOM     44  HE  ARG     2       1.157   4.735   7.597  1.00  0.00           H  
ATOM     45 1HH1 ARG     2      -0.328   5.043  10.715  1.00  0.00           H  
ATOM     46 2HH1 ARG     2       1.244   5.559  11.495  1.00  0.00           H  
ATOM     47 1HH2 ARG     2       2.912   5.317   8.501  1.00  0.00           H  
ATOM     48 2HH2 ARG     2       2.979   5.700  10.279  1.00  0.00           H  
ATOM     49  N   ARG     3      -3.173   0.866   5.574  1.00  0.00           N  
ATOM     50  CA  ARG     3      -2.628  -0.442   5.220  1.00  0.00           C  
ATOM     51  C   ARG     3      -2.718  -0.679   3.731  1.00  0.00           C  
ATOM     52  O   ARG     3      -1.767  -1.108   3.081  1.00  0.00           O  
ATOM     53  CB  ARG     3      -3.394  -1.537   6.015  1.00  0.00           C  
ATOM     54  CG  ARG     3      -2.767  -2.958   5.971  1.00  0.00           C  
ATOM     55  CD  ARG     3      -3.605  -3.984   6.751  1.00  0.00           C  
ATOM     56  NE  ARG     3      -2.946  -5.322   6.691  1.00  0.00           N  
ATOM     57  CZ  ARG     3      -3.409  -6.433   7.254  1.00  0.00           C  
ATOM     58  NH1 ARG     3      -4.519  -6.489   7.935  1.00  0.00           N1+
ATOM     59  NH2 ARG     3      -2.720  -7.523   7.122  1.00  0.00           N  
ATOM     60  H   ARG     3      -3.926   0.943   6.242  1.00  0.00           H  
ATOM     61  HA  ARG     3      -1.556  -0.452   5.496  1.00  0.00           H  
ATOM     62 2HB  ARG     3      -4.443  -1.571   5.653  1.00  0.00           H  
ATOM     63 3HB  ARG     3      -3.481  -1.222   7.075  1.00  0.00           H  
ATOM     64 2HG  ARG     3      -1.734  -2.913   6.373  1.00  0.00           H  
ATOM     65 3HG  ARG     3      -2.663  -3.295   4.919  1.00  0.00           H  
ATOM     66 2HD  ARG     3      -4.630  -4.027   6.320  1.00  0.00           H  
ATOM     67 3HD  ARG     3      -3.721  -3.645   7.803  1.00  0.00           H  
ATOM     68  HE  ARG     3      -2.061  -5.451   6.190  1.00  0.00           H  
ATOM     69 1HH1 ARG     3      -5.014  -5.597   8.000  1.00  0.00           H  
ATOM     70 2HH1 ARG     3      -4.806  -7.383   8.338  1.00  0.00           H  
ATOM     71 1HH2 ARG     3      -1.853  -7.460   6.585  1.00  0.00           H  
ATOM     72 2HH2 ARG     3      -3.091  -8.366   7.563  1.00  0.00           H  
ATOM     73  N   ARG     4      -3.826  -0.423   3.123  1.00  0.00           N  
ATOM     74  CA  ARG     4      -3.913  -0.652   1.683  1.00  0.00           C  
ATOM     75  C   ARG     4      -2.851   0.130   0.947  1.00  0.00           C  
ATOM     76  O   ARG     4      -2.150  -0.382   0.077  1.00  0.00           O  
ATOM     77  CB  ARG     4      -5.339  -0.266   1.199  1.00  0.00           C  
ATOM     78  CG  ARG     4      -5.695  -0.703  -0.250  1.00  0.00           C  
ATOM     79  CD  ARG     4      -7.107  -0.251  -0.659  1.00  0.00           C  
ATOM     80  NE  ARG     4      -7.400  -0.715  -2.047  1.00  0.00           N  
ATOM     81  CZ  ARG     4      -8.529  -0.489  -2.711  1.00  0.00           C  
ATOM     82  NH1 ARG     4      -9.533   0.183  -2.220  1.00  0.00           N1+
ATOM     83  NH2 ARG     4      -8.643  -0.963  -3.912  1.00  0.00           N  
ATOM     84  H   ARG     4      -4.622  -0.070   3.634  1.00  0.00           H  
ATOM     85  HA  ARG     4      -3.727  -1.727   1.496  1.00  0.00           H  
ATOM     86 2HB  ARG     4      -5.468   0.831   1.309  1.00  0.00           H  
ATOM     87 3HB  ARG     4      -6.088  -0.699   1.893  1.00  0.00           H  
ATOM     88 2HG  ARG     4      -5.601  -1.805  -0.331  1.00  0.00           H  
ATOM     89 3HG  ARG     4      -4.956  -0.287  -0.965  1.00  0.00           H  
ATOM     90 2HD  ARG     4      -7.173   0.857  -0.589  1.00  0.00           H  
ATOM     91 3HD  ARG     4      -7.849  -0.657   0.061  1.00  0.00           H  
ATOM     92  HE  ARG     4      -6.711  -1.258  -2.578  1.00  0.00           H  
ATOM     93 1HH1 ARG     4      -9.379   0.529  -1.271  1.00  0.00           H  
ATOM     94 2HH1 ARG     4     -10.368   0.312  -2.794  1.00  0.00           H  
ATOM     95 1HH2 ARG     4      -7.846  -1.486  -4.280  1.00  0.00           H  
ATOM     96 2HH2 ARG     4      -9.518  -0.780  -4.405  1.00  0.00           H  
ATOM     97  N   ARG     5      -2.674   1.374   1.241  1.00  0.00           N  
ATOM     98  CA  ARG     5      -1.648   2.130   0.529  1.00  0.00           C  
ATOM     99  C   ARG     5      -0.294   1.477   0.679  1.00  0.00           C  
ATOM    100  O   ARG     5       0.450   1.293  -0.281  1.00  0.00           O  
ATOM    101  CB  ARG     5      -1.636   3.589   1.068  1.00  0.00           C  
ATOM    102  CG  ARG     5      -0.798   4.602   0.237  1.00  0.00           C  
ATOM    103  CD  ARG     5      -0.812   6.008   0.858  1.00  0.00           C  
ATOM    104  NE  ARG     5      -0.011   6.939   0.009  1.00  0.00           N  
ATOM    105  CZ  ARG     5       0.189   8.229   0.261  1.00  0.00           C  
ATOM    106  NH1 ARG     5      -0.295   8.845   1.304  1.00  0.00           N1+
ATOM    107  NH2 ARG     5       0.903   8.916  -0.574  1.00  0.00           N  
ATOM    108  H   ARG     5      -3.237   1.818   1.952  1.00  0.00           H  
ATOM    109  HA  ARG     5      -1.904   2.125  -0.547  1.00  0.00           H  
ATOM    110 2HB  ARG     5      -1.284   3.577   2.120  1.00  0.00           H  
ATOM    111 3HB  ARG     5      -2.679   3.961   1.132  1.00  0.00           H  
ATOM    112 2HG  ARG     5      -1.188   4.631  -0.801  1.00  0.00           H  
ATOM    113 3HG  ARG     5       0.250   4.249   0.153  1.00  0.00           H  
ATOM    114 2HD  ARG     5      -0.400   5.959   1.890  1.00  0.00           H  
ATOM    115 3HD  ARG     5      -1.861   6.361   0.955  1.00  0.00           H  
ATOM    116  HE  ARG     5       0.441   6.612  -0.850  1.00  0.00           H  
ATOM    117 1HH1 ARG     5      -0.848   8.247   1.922  1.00  0.00           H  
ATOM    118 2HH1 ARG     5      -0.097   9.839   1.426  1.00  0.00           H  
ATOM    119 1HH2 ARG     5       1.275   8.417  -1.384  1.00  0.00           H  
ATOM    120 2HH2 ARG     5       1.044   9.905  -0.364  1.00  0.00           H  
ATOM    121  N   ARG     6       0.092   1.100   1.851  1.00  0.00           N  
ATOM    122  CA  ARG     6       1.400   0.468   1.996  1.00  0.00           C  
ATOM    123  C   ARG     6       1.510  -0.754   1.114  1.00  0.00           C  
ATOM    124  O   ARG     6       2.491  -0.957   0.403  1.00  0.00           O  
ATOM    125  CB  ARG     6       1.620   0.109   3.493  1.00  0.00           C  
ATOM    126  CG  ARG     6       3.066  -0.318   3.872  1.00  0.00           C  
ATOM    127  CD  ARG     6       3.187  -0.689   5.360  1.00  0.00           C  
ATOM    128  NE  ARG     6       4.597  -1.069   5.669  1.00  0.00           N  
ATOM    129  CZ  ARG     6       5.046  -1.451   6.861  1.00  0.00           C  
ATOM    130  NH1 ARG     6       4.292  -1.546   7.920  1.00  0.00           N1+
ATOM    131  NH2 ARG     6       6.303  -1.745   6.980  1.00  0.00           N  
ATOM    132  H   ARG     6      -0.502   1.239   2.656  1.00  0.00           H  
ATOM    133  HA  ARG     6       2.168   1.192   1.662  1.00  0.00           H  
ATOM    134 2HB  ARG     6       0.901  -0.686   3.778  1.00  0.00           H  
ATOM    135 3HB  ARG     6       1.332   0.977   4.120  1.00  0.00           H  
ATOM    136 2HG  ARG     6       3.767   0.503   3.618  1.00  0.00           H  
ATOM    137 3HG  ARG     6       3.382  -1.182   3.253  1.00  0.00           H  
ATOM    138 2HD  ARG     6       2.491  -1.526   5.588  1.00  0.00           H  
ATOM    139 3HD  ARG     6       2.861   0.171   5.984  1.00  0.00           H  
ATOM    140  HE  ARG     6       5.320  -1.050   4.943  1.00  0.00           H  
ATOM    141 1HH1 ARG     6       3.313  -1.301   7.758  1.00  0.00           H  
ATOM    142 2HH1 ARG     6       4.714  -1.846   8.801  1.00  0.00           H  
ATOM    143 1HH2 ARG     6       6.878  -1.666   6.139  1.00  0.00           H  
ATOM    144 2HH2 ARG     6       6.629  -2.034   7.903  1.00  0.00           H  
ATOM    145  N   ARG     7       0.545  -1.610   1.109  1.00  0.00           N  
ATOM    146  CA  ARG     7       0.652  -2.791   0.257  1.00  0.00           C  
ATOM    147  C   ARG     7       0.861  -2.399  -1.187  1.00  0.00           C  
ATOM    148  O   ARG     7       1.724  -2.924  -1.886  1.00  0.00           O  
ATOM    149  CB  ARG     7      -0.631  -3.652   0.430  1.00  0.00           C  
ATOM    150  CG  ARG     7      -0.570  -5.077  -0.188  1.00  0.00           C  
ATOM    151  CD  ARG     7      -1.889  -5.844  -0.003  1.00  0.00           C  
ATOM    152  NE  ARG     7      -1.764  -7.208  -0.597  1.00  0.00           N  
ATOM    153  CZ  ARG     7      -2.723  -8.129  -0.616  1.00  0.00           C  
ATOM    154  NH1 ARG     7      -3.911  -7.944  -0.114  1.00  0.00           N1+
ATOM    155  NH2 ARG     7      -2.464  -9.275  -1.163  1.00  0.00           N  
ATOM    156  H   ARG     7      -0.272  -1.465   1.685  1.00  0.00           H  
ATOM    157  HA  ARG     7       1.541  -3.366   0.579  1.00  0.00           H  
ATOM    158 2HB  ARG     7      -1.494  -3.092   0.013  1.00  0.00           H  
ATOM    159 3HB  ARG     7      -0.863  -3.745   1.511  1.00  0.00           H  
ATOM    160 2HG  ARG     7       0.272  -5.636   0.268  1.00  0.00           H  
ATOM    161 3HG  ARG     7      -0.333  -5.011  -1.270  1.00  0.00           H  
ATOM    162 2HD  ARG     7      -2.716  -5.276  -0.485  1.00  0.00           H  
ATOM    163 3HD  ARG     7      -2.136  -5.902   1.079  1.00  0.00           H  
ATOM    164  HE  ARG     7      -0.890  -7.514  -1.036  1.00  0.00           H  
ATOM    165 1HH1 ARG     7      -4.050  -7.020   0.301  1.00  0.00           H  
ATOM    166 2HH1 ARG     7      -4.593  -8.703  -0.167  1.00  0.00           H  
ATOM    167 1HH2 ARG     7      -1.528  -9.401  -1.552  1.00  0.00           H  
ATOM    168 2HH2 ARG     7      -3.213  -9.970  -1.166  1.00  0.00           H  
ATOM    169  N   ARG     8       0.111  -1.484  -1.701  1.00  0.00           N  
ATOM    170  CA  ARG     8       0.313  -1.104  -3.096  1.00  0.00           C  
ATOM    171  C   ARG     8       1.735  -0.650  -3.332  1.00  0.00           C  
ATOM    172  O   ARG     8       2.398  -1.055  -4.284  1.00  0.00           O  
ATOM    173  CB  ARG     8      -0.706   0.010  -3.465  1.00  0.00           C  
ATOM    174  CG  ARG     8      -0.819   0.339  -4.981  1.00  0.00           C  
ATOM    175  CD  ARG     8      -1.820   1.473  -5.253  1.00  0.00           C  
ATOM    176  NE  ARG     8      -1.901   1.730  -6.721  1.00  0.00           N  
ATOM    177  CZ  ARG     8      -2.667   2.650  -7.300  1.00  0.00           C  
ATOM    178  NH1 ARG     8      -3.449   3.457  -6.640  1.00  0.00           N1+
ATOM    179  NH2 ARG     8      -2.637   2.752  -8.592  1.00  0.00           N  
ATOM    180  H   ARG     8      -0.605  -1.036  -1.146  1.00  0.00           H  
ATOM    181  HA  ARG     8       0.140  -2.000  -3.722  1.00  0.00           H  
ATOM    182 2HB  ARG     8      -0.453   0.928  -2.895  1.00  0.00           H  
ATOM    183 3HB  ARG     8      -1.710  -0.277  -3.091  1.00  0.00           H  
ATOM    184 2HG  ARG     8      -1.111  -0.579  -5.531  1.00  0.00           H  
ATOM    185 3HG  ARG     8       0.175   0.624  -5.383  1.00  0.00           H  
ATOM    186 2HD  ARG     8      -1.498   2.387  -4.708  1.00  0.00           H  
ATOM    187 3HD  ARG     8      -2.815   1.196  -4.843  1.00  0.00           H  
ATOM    188  HE  ARG     8      -1.345   1.180  -7.384  1.00  0.00           H  
ATOM    189 1HH1 ARG     8      -3.425   3.325  -5.626  1.00  0.00           H  
ATOM    190 2HH1 ARG     8      -4.007   4.137  -7.159  1.00  0.00           H  
ATOM    191 1HH2 ARG     8      -2.017   2.111  -9.092  1.00  0.00           H  
ATOM    192 2HH2 ARG     8      -3.233   3.463  -9.017  1.00  0.00           H  
ATOM    193  N   ARG     9       2.268   0.188  -2.509  1.00  0.00           N  
ATOM    194  CA  ARG     9       3.642   0.626  -2.737  1.00  0.00           C  
ATOM    195  C   ARG     9       4.584  -0.553  -2.790  1.00  0.00           C  
ATOM    196  O   ARG     9       5.428  -0.674  -3.674  1.00  0.00           O  
ATOM    197  CB  ARG     9       4.043   1.623  -1.612  1.00  0.00           C  
ATOM    198  CG  ARG     9       5.370   2.398  -1.847  1.00  0.00           C  
ATOM    199  CD  ARG     9       5.709   3.332  -0.674  1.00  0.00           C  
ATOM    200  NE  ARG     9       6.977   4.063  -0.965  1.00  0.00           N  
ATOM    201  CZ  ARG     9       7.559   4.944  -0.157  1.00  0.00           C  
ATOM    202  NH1 ARG     9       7.084   5.277   1.011  1.00  0.00           N1+
ATOM    203  NH2 ARG     9       8.659   5.506  -0.549  1.00  0.00           N  
ATOM    204  H   ARG     9       1.743   0.535  -1.719  1.00  0.00           H  
ATOM    205  HA  ARG     9       3.681   1.130  -3.722  1.00  0.00           H  
ATOM    206 2HB  ARG     9       4.089   1.073  -0.649  1.00  0.00           H  
ATOM    207 3HB  ARG     9       3.225   2.357  -1.469  1.00  0.00           H  
ATOM    208 2HG  ARG     9       5.292   2.974  -2.793  1.00  0.00           H  
ATOM    209 3HG  ARG     9       6.205   1.685  -2.000  1.00  0.00           H  
ATOM    210 2HD  ARG     9       5.801   2.733   0.258  1.00  0.00           H  
ATOM    211 3HD  ARG     9       4.869   4.042  -0.510  1.00  0.00           H  
ATOM    212  HE  ARG     9       7.482   3.915  -1.844  1.00  0.00           H  
ATOM    213 1HH1 ARG     9       6.216   4.797   1.260  1.00  0.00           H  
ATOM    214 2HH1 ARG     9       7.590   5.963   1.572  1.00  0.00           H  
ATOM    215 1HH2 ARG     9       9.016   5.233  -1.466  1.00  0.00           H  
ATOM    216 2HH2 ARG     9       9.089   6.182   0.085  1.00  0.00           H  
ATOM    217  N   ARG    10       4.499  -1.462  -1.878  1.00  0.00           N  
ATOM    218  CA  ARG    10       5.410  -2.602  -1.929  1.00  0.00           C  
ATOM    219  C   ARG    10       5.290  -3.331  -3.247  1.00  0.00           C  
ATOM    220  O   ARG    10       6.277  -3.661  -3.900  1.00  0.00           O  
ATOM    221  CB  ARG    10       5.101  -3.544  -0.731  1.00  0.00           C  
ATOM    222  CG  ARG    10       6.150  -4.663  -0.471  1.00  0.00           C  
ATOM    223  CD  ARG    10       5.750  -5.568   0.706  1.00  0.00           C  
ATOM    224  NE  ARG    10       6.801  -6.606   0.920  1.00  0.00           N  
ATOM    225  CZ  ARG    10       6.766  -7.553   1.852  1.00  0.00           C  
ATOM    226  NH1 ARG    10       5.788  -7.694   2.702  1.00  0.00           N1+
ATOM    227  NH2 ARG    10       7.758  -8.384   1.922  1.00  0.00           N  
ATOM    228  H   ARG    10       3.814  -1.386  -1.140  1.00  0.00           H  
ATOM    229  HA  ARG    10       6.444  -2.217  -1.852  1.00  0.00           H  
ATOM    230 2HB  ARG    10       4.096  -3.991  -0.879  1.00  0.00           H  
ATOM    231 3HB  ARG    10       4.997  -2.937   0.191  1.00  0.00           H  
ATOM    232 2HG  ARG    10       7.139  -4.197  -0.285  1.00  0.00           H  
ATOM    233 3HG  ARG    10       6.278  -5.282  -1.382  1.00  0.00           H  
ATOM    234 2HD  ARG    10       4.765  -6.038   0.490  1.00  0.00           H  
ATOM    235 3HD  ARG    10       5.610  -4.949   1.618  1.00  0.00           H  
ATOM    236  HE  ARG    10       7.640  -6.636   0.332  1.00  0.00           H  
ATOM    237 1HH1 ARG    10       5.038  -7.009   2.592  1.00  0.00           H  
ATOM    238 2HH1 ARG    10       5.837  -8.448   3.390  1.00  0.00           H  
ATOM    239 1HH2 ARG    10       8.515  -8.258   1.247  1.00  0.00           H  
ATOM    240 2HH2 ARG    10       7.714  -9.103   2.646  1.00  0.00           H  
ATOM    241  N   ARG    11       4.116  -3.614  -3.700  1.00  0.00           N  
ATOM    242  CA  ARG    11       4.001  -4.318  -4.974  1.00  0.00           C  
ATOM    243  C   ARG    11       4.693  -3.554  -6.078  1.00  0.00           C  
ATOM    244  O   ARG    11       5.455  -4.103  -6.871  1.00  0.00           O  
ATOM    245  CB  ARG    11       2.494  -4.533  -5.293  1.00  0.00           C  
ATOM    246  CG  ARG    11       2.191  -5.503  -6.469  1.00  0.00           C  
ATOM    247  CD  ARG    11       0.682  -5.631  -6.739  1.00  0.00           C  
ATOM    248  NE  ARG    11       0.452  -6.588  -7.861  1.00  0.00           N  
ATOM    249  CZ  ARG    11      -0.736  -6.923  -8.355  1.00  0.00           C  
ATOM    250  NH1 ARG    11      -1.863  -6.440  -7.912  1.00  0.00           N1+
ATOM    251  NH2 ARG    11      -0.780  -7.776  -9.329  1.00  0.00           N  
ATOM    252  H   ARG    11       3.289  -3.353  -3.182  1.00  0.00           H  
ATOM    253  HA  ARG    11       4.510  -5.295  -4.873  1.00  0.00           H  
ATOM    254 2HB  ARG    11       2.026  -3.545  -5.485  1.00  0.00           H  
ATOM    255 3HB  ARG    11       1.980  -4.907  -4.384  1.00  0.00           H  
ATOM    256 2HG  ARG    11       2.630  -6.497  -6.244  1.00  0.00           H  
ATOM    257 3HG  ARG    11       2.699  -5.154  -7.391  1.00  0.00           H  
ATOM    258 2HD  ARG    11       0.266  -4.628  -6.982  1.00  0.00           H  
ATOM    259 3HD  ARG    11       0.167  -5.970  -5.815  1.00  0.00           H  
ATOM    260  HE  ARG    11       1.237  -7.057  -8.324  1.00  0.00           H  
ATOM    261 1HH1 ARG    11      -1.759  -5.772  -7.145  1.00  0.00           H  
ATOM    262 2HH1 ARG    11      -2.736  -6.748  -8.344  1.00  0.00           H  
ATOM    263 1HH2 ARG    11       0.113  -8.143  -9.663  1.00  0.00           H  
ATOM    264 2HH2 ARG    11      -1.702  -8.021  -9.693  1.00  0.00           H  
ATOM    265  N   ARG    12       4.479  -2.287  -6.191  1.00  0.00           N  
ATOM    266  CA  ARG    12       5.149  -1.549  -7.258  1.00  0.00           C  
ATOM    267  C   ARG    12       6.649  -1.705  -7.164  1.00  0.00           C  
ATOM    268  O   ARG    12       7.178  -2.396  -6.297  1.00  0.00           O  
ATOM    269  CB  ARG    12       4.729  -0.054  -7.174  1.00  0.00           C  
ATOM    270  CG  ARG    12       5.128   0.823  -8.394  1.00  0.00           C  
ATOM    271  CD  ARG    12       4.707   2.290  -8.214  1.00  0.00           C  
ATOM    272  NE  ARG    12       5.096   3.077  -9.421  1.00  0.00           N  
ATOM    273  CZ  ARG    12       4.886   4.378  -9.591  1.00  0.00           C  
ATOM    274  NH1 ARG    12       4.309   5.138  -8.703  1.00  0.00           N1+
ATOM    275  NH2 ARG    12       5.276   4.925 -10.700  1.00  0.00           N  
ATOM    276  H   ARG    12       3.858  -1.813  -5.552  1.00  0.00           H  
ATOM    277  HA  ARG    12       4.828  -1.980  -8.226  1.00  0.00           H  
ATOM    278 2HB  ARG    12       5.143   0.380  -6.241  1.00  0.00           H  
ATOM    279 3HB  ARG    12       3.629   0.007  -7.040  1.00  0.00           H  
ATOM    280 2HG  ARG    12       4.673   0.398  -9.313  1.00  0.00           H  
ATOM    281 3HG  ARG    12       6.225   0.774  -8.555  1.00  0.00           H  
ATOM    282 2HD  ARG    12       5.189   2.701  -7.300  1.00  0.00           H  
ATOM    283 3HD  ARG    12       3.610   2.343  -8.042  1.00  0.00           H  
ATOM    284  HE  ARG    12       5.564   2.627 -10.215  1.00  0.00           H  
ATOM    285 1HH1 ARG    12       4.028   4.647  -7.852  1.00  0.00           H  
ATOM    286 2HH1 ARG    12       4.183   6.131  -8.910  1.00  0.00           H  
ATOM    287 1HH2 ARG    12       5.727   4.314 -11.383  1.00  0.00           H  
ATOM    288 2HH2 ARG    12       5.104   5.925 -10.812  1.00  0.00           H  
TER   
END
`], {
    type: 'text/plain'
});

export default dummypdb;