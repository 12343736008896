import { Callout } from '@blueprintjs/core';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

interface stateType {
    id: Number
}

export default function SubmissionSuccess() {
    const location = useLocation<stateType>();
    const [id, setId] = React.useState<Number>(location.state != null ? location.state.id : 0)

    return(
        <div>
            {location.state != null ?
                <Callout intent="success" title="Job submitted!">
                    <p>Your job was successfully submitted! To access information about its progress, please note the following <i>unique identifier</i>:</p>
                    <p><b>{id}</b></p>
                    <p>Information about your submission can be retrieved on <Link to={`/info?id=${id}`}>this</Link> page.</p>
                </Callout>  
                :
                <Callout intent="danger" title="You shouldn't be here.">
                    This page is displayed after submission of a job,
                    yet you came here without submitting anything.
                </Callout>
            }
        </div>
    )
};