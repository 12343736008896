import React from "react";
import { Container, Row, Col } from "react-grid-system"
import GAIMG from "./assets/GA_V3.png";
import KREUZ from "./assets/kreuz_v3.png";
import DIAGRAM from "./assets/diagram-y-new.png"

export default function Principle() {
    return (
        <Container className="Principle">
            <Row>
                <Col md={12}>
                    <h1>D-peptide ligand identification</h1>
                </Col>
                <Col md={6} style={{textAlign: "justify"}}>
                    <p className="bp3-running-text bp3-text-large">In the rapidly expanding field of peptide therapeutics the peptides’ low in vivo half life represents a considerable limitation. D-peptides, consisting entirely of the dextrorotatory enantiomers of naturally occurring levorotatory amino acids, do not suffer from these shortcomings as they are intrinsically protease resistant, leading to a favourable pharmacokinetic profile. finDr is a web server for the computational identification and optimization of D-peptide ligands to any protein structure. </p>
                    <p className="bp3-running-text bp3-text-large">finDr offers molecular docking based virtual screening (MIVS) for de novo ligand identification from a helical 12-mer peptide structure library which was extracted from the RCSB Protein Data Bank (PDB). In a second, heuristic approach to search the chemical space of 12-mer peptides, finDr allows the user to perform a custom-tailored mirror-image evolutionary algorithm (MIEA) for the de novo identification or optimization of D-peptide ligands.</p>
                    <p className="bp3-running-text bp3-text-large">MIEA and MIVS are based on the fact that an L-protein and a D-protein of the same sequence are stereochemical mirror image counterparts. This also applies to their binding behaviour: If an L-peptide binds to a D-protein, their corresponding mirror-images (D-peptide and L-protein) will also bind to each other in exactly the same fashion. This principle is utilized for indirect D-peptide ligand identification.</p>
                </Col>
                <Col md={6} style={{textAlign: "center"}}>
                    <div className="ImgWithCaption">
                        <img src={KREUZ} alt="Mirror-image principle" style={{maxWidth: "50%"}}/>
                        <p style={{paddingTop: "10px"}}>Figure 1: Mirror-image principle.</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
                </Col>
                <Col md={6} style={{width: "100%", height: "100%"}}>

                </Col>
                <Col md={6} style={{textAlign: "justify"}}>
                    <h2>MIVS (Mirror-Image Virtual Screening)</h2>
                    <p className="bp3-running-text bp3-text-large">For virtual screening one needs a target protein structure and a library of compounds and a scoring function to evaluate their binding. In the case of MIVS, a target protein structure (which can be obtained from the RCSB PDB) and is at first inverted to its mirror-image, D-analogue by finDr. Then a library of helical 12-mer L-peptides (which finDr provides) is screened for binding to this target using the established molecular docking software AutoDock Vina. As a last step, the identified L-peptide ligand binding to the D-protein target is again inverted to obtain the structure of a D-peptide ligand binding to the naturally occurring L-target protein structure. </p>
                </Col>
                <Col md={12}>
                    <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
                    <h2 id="MIEA">MIEA (Mirror-Image Evolutionary Algorithm)</h2>
                </Col>
                <Col md={6} style={{textAlign: "justify"}}>
                    <p className="bp3-running-text bp3-text-large">An initial population of peptides, which can either be of random origin or integrate individually chosen peptides from MIVS or other sources serves as a starting point for the algorithm. The fitness of all peptides in a population Pn is evaluated by molecular docking. Based on this a population Pn+1 is newly generated by copying the peptides with the lowest binding energy with and without crossover recombination sequences and introducing random mutations. Based on the elitism principle, a number of X individual peptides with the best binding energy are directly copied into the population Pn+1 without alteration. This population Pn+1 is then again evaluated via docking completing the MIEA cycle. </p>
                </Col>
                <Col md={6} style={{textAlign: "center"}}>
                    <div className="ImgWithCaption">
                        <img src={GAIMG} alt="Evolutionary algorithm principle" style={{maxWidth: "100%"}}/>
                        <p style={{paddingTop: "10px"}}>Figure 2: Operation of Evolutionary Algorithm.</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
                    <h2 id="MIEA">finDr Workflow</h2>
                </Col>
                <Col md={12} style={{textAlign: "center"}}>
                    <div className="ImgWithCaption">
                        <img src={DIAGRAM} alt="Workflow" style={{maxWidth: "700px"}}/>
                        <p style={{paddingTop: "10px"}}>Figure 3: Workflow of finDr.</p>
                    </div>
                </Col>     
            </Row>
        </Container>
    )
}