import React, { useEffect } from 'react'
import axios from 'axios'
import { Card, IToaster, H3, H4, HTMLTable } from '@blueprintjs/core'
import { ResponsiveScatterPlot, Serie } from "@nivo/scatterplot"
import { ResponsiveLine } from "@nivo/line"
import { Row, Col } from "react-grid-system"
import { Chart } from "react-google-charts"

interface IVSBinders {
    affinity: number,
    FASTA: string,
}

interface IBestBinders {
    generation: number,
    affinity: number,
    FASTA: string
}

interface IAvgBinders {
    generation: number,
    avgaffinity: number,
    FASTA: string
}

interface IAllBinders {
    generation: number,
    affinity: number,
    FASTA: string,
}

interface IAllBindersInternal {
    key: number,
    generation: number,
    affinity: number,
    FASTA: string,
}

interface IProps {
    id: string,
    setLoading: Function,
    ea: boolean | undefined,
    prevs: boolean | undefined,
    noGens: number | undefined,
    popSize: number | undefined,
    toaster: IToaster
}

function ResultsEA(props: IProps) {
    const [bestBinders, setBestBinders] = React.useState<IBestBinders[]>([]);
    const [bestBindersGraphData, setBestBindersGraphData] = React.useState<Serie[]>([])
    const [avgBinders, setAvgBinders] = React.useState<IAvgBinders[]>()
    const [avgBindersGraphData, setAvgBindersGraphData] = React.useState<Serie[]>([])
    const [allBinders, setAllBinders] = React.useState<IAllBinders[]>([])
    const [allBindersInternal, setAllBindersInternal] = React.useState<IAllBindersInternal[]>([])
    const [allBindersGraphData, setAllBindersGraphData] = React.useState<Serie[]>([])
    const [topBinders, setTopBinders] = React.useState<IAllBinders[]>([]);
    const [topBindersInternal, setTopBindersInternal] = React.useState<IAllBinders[]>([]);
    const [topBindersGraphData, setTopBindersGraphData] = React.useState<Serie[]>([])
    const [vsBinders, setVsBinders] = React.useState<IVSBinders[]>([]);
    const [chartHistogramData, setChartHistogramData] = React.useState<(string|number)[][]>([])

    useEffect(() => {
        getInfoEA();
        if (props.prevs) {
            getInfoPreVS();
        }
    }, [props.id, props.prevs])


    function getInfoPreVS() {
        axios.get("/jobinfo/dockings/VS", {
            params: {
                id: props.id
            }
        }).then((response) => {
            console.log(response.data);
            var vsBindersResponse: IVSBinders[] = response.data;
            setVsBinders(vsBindersResponse.sort((a, b) => a.affinity - b.affinity));
            var chartData: (string|number)[][] = [['FASTA', 'Affinity']];
            vsBindersResponse.map((value, idx) => {
                chartData.push([value.FASTA, value.affinity])
            });
            setChartHistogramData(chartData);
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst fetching VS affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }

    function getInfoEA() {
        props.setLoading(true);
        axios.get("/jobinfo/dockings/best", {
            params: {
                id: props.id
            }
        }).then((response) => {
            var bestBinders: IBestBinders[] = response.data;
            setBestBinders(bestBinders)
            setBestBindersGraphData([{
                "id": "Top affinities",
                "data": bestBinders.map((value) => {
                    return {
                        "x": value.generation,
                        "y": value.affinity
                    }
                })
            }]);

            axios.get("/jobinfo/dockings/avg", {
                params: {
                    id: props.id
                }
            }).then((response) => {
                var avgBinders: IAvgBinders[] = response.data;
                setAvgBinders(avgBinders)
                setAvgBindersGraphData([{
                    "id": "Average affinities",
                    "data": avgBinders.map((value) => {
                        return {
                            "x": value.generation,
                            "y": value.avgaffinity.toFixed(4)
                        }
                    })
                }]);

                axios.get("/jobinfo/dockings/all", {
                    params: {
                        id: props.id
                    }
                }).then((response) => {
                    var allBinders: IAllBinders[] = response.data;
                    setAllBinders(allBinders)
                    setAllBindersInternal(allBinders.map((value, idx) => { return { key: idx, ...value } }));
                    setAllBindersGraphData([{
                            "id": "Binding affinities",
                            "data": allBinders.map((value) => {
                                return {
                                    "x": value.generation + (-0.25 + (Math.random() * 0.5)),
                                    "y": value.affinity
                                }
                            })
                    }]);
                    axios.get("/jobinfo/dockings/top", {
                        params: {
                            id: props.id,
                            popSize: props.popSize
                        }
                    }).then((response) => {
                        var topBinders: IAllBinders[] = response.data;
                        setTopBinders(topBinders)
                        setTopBindersInternal(topBinders.map((value, idx) => { return { key: idx, ...value } }));
                        setTopBindersGraphData([{
                                "id": "Binding affinities",
                                "data": topBinders.map((value) => {
                                    return {
                                        "x": value.generation + (-0.25 + (Math.random() * 0.5)),
                                        "y": value.affinity
                                    }
                                })
                        }]);
                        props.setLoading(false);
                    }).catch(
                        (error) => {
                            props.toaster.show({message: "Error whilst fetching top 25% affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                        }
                    )
                }).catch(
                    (error) => {
                        props.toaster.show({message: "Error whilst fetching all affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                    }
                )
            }).catch(
                (error) => {
                    props.toaster.show({message: "Error whilst fetching avg affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                }
            )
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst fetching best affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }

    function getSequence(idx: number) {
        return allBindersInternal[idx].FASTA;
    }

    function getSequenceTop(idx: number) {
        return topBindersInternal[idx].FASTA;
    }

    function getAllSequences(x: number, y: number) {
        const relevant = allBinders.filter((value) => 
            value.generation >= x - 0.25 && value.generation < x + 0.25 && value.affinity === y
        )
        return relevant.map((value) => value.FASTA).join(", ")
    }

    return(
        <div>
            <Row nogutter={true}>
                <Col lg={6} xl={3}>
                    <Card elevation={1} style={{height: "350px"}}>
                        <H3>Best binder per generation</H3>
                        <ResponsiveLine data={bestBindersGraphData}
                                        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickValues: Math.min(10, bestBinders.length),
                                            tickRotation: 0,
                                            legend: 'generation',
                                            legendPosition: 'middle',
                                            legendOffset: 40
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Binding energy [kcal/mol]',
                                            legendPosition: 'middle',
                                            legendOffset: -45
                                        }}
                                        isInteractive={true}
                                        enableCrosshair={true}
                                        useMesh={true}
                                        colors={["#106BA3"]}
                                        tooltip={(value) => {
                                            return <Card elevation={3} className="graphTooltip"><div>Sequence(s): {getAllSequences(value.point.data.x as number, value.point.data.y as number)}</div>
                                                                    <div>Binding energy [kcal/mol]: {value.point.data.y}</div>
                                                                    <div>Generation: {value.point.data.x}</div></Card>
                                        }}>

                        </ResponsiveLine>
                    </Card>
                </Col>
                <Col lg={6} xl={3}>
                    <Card elevation={1} style={{height: "350px"}}>
                        <H3>Avgerage binding energies per generation</H3>
                        <ResponsiveLine data={avgBindersGraphData}
                                        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            tickValues: Math.min(10, bestBinders.length),
                                            legend: 'generation',
                                            legendPosition: 'middle',
                                            legendOffset: 40
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Binding energy [kcal/mol]',
                                            legendPosition: 'middle',
                                            legendOffset: -45
                                        }}
                                        useMesh={true}
                                        isInteractive={true}
                                        tooltip={(value) => {
                                            return <Card elevation={3} className="graphTooltip">
                                                                    <div>Average binding energy [kcal/mol]: {value.point.data.y}</div>
                                                                    <div>Generation: {value.point.data.x}</div></Card>
                                        }}
                                        colors={["#106BA3"]}>
                        
                        </ResponsiveLine>
                    </Card>
                </Col>
                <Col lg={12} xl={6}>
                    <Card elevation={1} style={{height: "350px"}}>
                        <H3>Top 25% binders per generation</H3>
                        <ResponsiveScatterPlot data={topBindersGraphData}
                                    xScale={{ type: 'linear', min: -0.5, max: 'auto' }}
                                    margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'generation',
                                        legendPosition: 'middle',
                                        tickValues: Math.min(10, bestBinders.length),
                                        legendOffset: 40
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Binding energy [kcal/mol]',
                                        legendPosition: 'middle',
                                        legendOffset: -45
                                    }}
                                    useMesh={false}
                                    nodeSize={7}
                                    tooltip={(value) => {
                                        return <Card elevation={3} className="graphTooltip"><div>Sequence(s): {getSequenceTop(value.node.index)}</div>
                                                                <div>Binding energy [kcal/mol]: {value.node.data.y}</div>
                                                                <div>Generation: {Math.round(value.node.data.x as number)}</div></Card>
                                    }}
                                    colors={["#106BA3"]}>
                        </ResponsiveScatterPlot>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card elevation={1} style={{height: "400px"}}>
                        <H3>All binders</H3>
                        <ResponsiveScatterPlot data={allBindersGraphData}
                                    xScale={{ type: 'linear', min: -0.5, max: 'auto' }}
                                    margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
                                    yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'generation',
                                        legendPosition: 'middle',
                                        tickValues: Math.min(10, bestBinders.length),
                                        legendOffset: 40
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Binding energy [kcal/mol]',
                                        legendPosition: 'middle',
                                        legendOffset: -45
                                    }}
                                    useMesh={false}
                                    nodeSize={7}
                                    tooltip={(value) => {
                                        return <Card elevation={3} className="graphTooltip"><div>Sequence(s): {getSequence(value.node.index)}</div>
                                                                <div>Binding energy [kcal/mol]: {value.node.data.y}</div>
                                                                <div>Generation: {Math.round(value.node.data.x as number)}</div></Card>
                                    }}
                                    colors={["#106BA3"]}>
                        </ResponsiveScatterPlot>
                    </Card> 
                </Col>
            </Row>
            {props.prevs
                ?
                <Card elevation={1} style={{height: "550px"}}>
                    <Row>
                        <Col md={12}>
                            <H3> Results from pre-Virtual Screening </H3>
                        </Col>
                        <Col md={4}>       
                            <H4> Top 10 binders </H4>
                            <HTMLTable bordered striped>
                                <thead>
                                    <tr>
                                        <th>FASTA Sequence</th>
                                        <th>Binding energy [kcal/mol]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vsBinders.map((value, idx) => {
                                        if (idx < 10) {
                                            return(
                                                <tr>
                                                    <td>{value.FASTA}</td>
                                                    <td>{value.affinity}</td>
                                                </tr>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </tbody>
                            </HTMLTable>
                        </Col>
                        <Col md={8}>
                            <H4> All binders </H4>
                            {chartHistogramData.length > 0
                            ?
                                <Chart
                                    width='100%'
                                    height='450px'
                                    chartType="Histogram"
                                    data={chartHistogramData}
                                    options={{
                                        // title: "Binding affinities",
                                        hAxis: { title: 'Binding energy [kcal/mol]' },
                                        vAxis: { title: 'no. of binders'},
                                        legend: 'none',
                                        colors: ['#106ba3'],
                                        // vAxis: { scaleType: 'mirrorLog' }, // For logarithmic scale
                                        histogram: {
                                            hideBucketItems: false // Set to false for separate items
                                        }
                                    }}
                                />
                            : null}
                        </Col>
                    </Row>
                </Card>
                : null}
        </div>)
}

function ResultsVS(props: IProps) {
    const [vsBinders, setVsBinders] = React.useState<IVSBinders[]>([]);
    const [chartHistogramData, setChartHistogramData] = React.useState<(string|number)[][]>([])

    useEffect(() => {
        getInfoVS();
    }, [props.id])

    function getInfoVS() {
        axios.get("/jobinfo/dockings/VS", {
            params: {
                id: props.id
            }
        }).then((response) => {
            console.log(response.data);
            var vsBindersResponse: IVSBinders[] = response.data;
            setVsBinders(vsBindersResponse.sort((a, b) => a.affinity - b.affinity));
            var chartData: (string|number)[][] = [['FASTA', 'Affinity']];
            vsBindersResponse.map((value, idx) => {
                chartData.push([value.FASTA, value.affinity])
            });
            setChartHistogramData(chartData);
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst fetching VS affinities: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }
    
    return(
            <Row nogutter style={{height: "550px"}}>
                <Col md={4}>
                    <Card elevation={1} style={{height: "550px"}}>
                        <H3> Top 10 binders </H3>
                        <HTMLTable bordered striped>
                            <thead>
                                <tr>
                                    <th>FASTA Sequence</th>
                                    <th>Binding energy [kcal/mol]</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vsBinders.map((value, idx) => {
                                    if (idx < 10) {
                                        return(
                                            <tr>
                                                <td>{value.FASTA}</td>
                                                <td>{value.affinity}</td>
                                            </tr>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </tbody>
                        </HTMLTable>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card elevation={1}  style={{height: "550px"}}>
                        <H3> All {vsBinders.length} binders </H3>
                        <Chart
                            width='100%'
                            height='450px'
                            chartType="Histogram"
                            data={chartHistogramData}
                            options={{
                                // title: "Binding affinities",
                                hAxis: { title: 'Binding energy [kcal/mol]' },
                                vAxis: { title: 'no. of binders'},
                                legend: 'none',
                                colors: ['#106ba3'],
                                // vAxis: { scaleType: 'mirrorLog' }, // For logarithmic scale
                                histogram: {
                                    hideBucketItems: false // Set to false for separate items
                                }
                            }}
                        />
                    </Card>
                </Col>
            </Row>
    )
}

export default function SubmissionResult(props : IProps) {
    return (
        <div>
            {props.ea
            ? <ResultsEA {...props}/>
            : <ResultsVS {...props}/>}
        </div>
    )
};
