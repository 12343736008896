import React from 'react';
import { Callout, IToaster} from '@blueprintjs/core';
import { Container, Row, Col } from "react-grid-system";
import { SubmissionInfoExample } from "./components/SubmissionInfo"
import residueimg from "./assets/residueselect.png"
import eaparams from "./assets/eaparams1.png"
import vsparams from "./assets/vsparams.png"
import eaorvs from "./assets/eaorvs.png"
import fileupload from "./assets/fileupload.png"


export default function Example(props: {toaster :IToaster, setLoading: Function}) {
    function getStyle(x: number) {
        return { "--start: ": x} as React.CSSProperties;
    }
    return (
        <Container className="Example">
            <Row>
                <Col md={7} style={{textAlign: "justify"}}>
                    <h1>Example: Human growth factor receptor 2 (ErbB2)</h1>
                    <p className="bp3-running-text bp3-text-large"> finDr can for example be used to identify D-peptide ligands for the human growth factor receptor 2 (ErbB2) (PDB ID: 1S78,  Fanklin et al. 2004), which is overexpressed in several types of human cancer and is of critical importance for their pathology. In the following we will explain step by step how to achieve this.</p>
                    <ol>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                Submit your target protein structure to finDr: You can obtain a structural file for your target protein of choice in the PDB format on the <a rel="noopener noreferrer" target="_blank" href="https://www.rcsb.org/">RCSB Protein Data Bank</a>. All protein structures are labelled with a 4 letter ID - the PDB ID of the human growth factor receptor 2 (ErbB2) which serves as example in this case is 1S78 and will be used to refer to the target protein in the following. Upload this structure to finDr. Please be sure not to upload structures containing DNA, RNA or carbohydrates. 
                            </p>
                            <Callout intent="primary" icon="info-sign"> <i>finDr will now prepare your target protein structure for docking. In this process, for example any remaining crystal waters in your structure will be removed.</i></Callout>
                        </li>
                    </ol>
                </Col>
                <Col md={5}>
                    <div className="ImgWithCaption">
                        <img src={fileupload} alt="Target structure upload screen" style={{maxWidth: "100%"}}></img>
                        <p style={{paddingTop: "10px"}}>Figure 1: Target structure upload screen.</p>
                    </div>
                </Col>
            </Row>
            <Col md={12}>
                <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
            </Col>
            <Row>
                <Col md={5}>
                    <div className="ImgWithCaption">
                        <img src={residueimg} alt="Residue selection screen" style={{maxWidth: "100%"}}></img>
                        <p style={{paddingTop: "10px"}}>Figure 2: Residue selection screen.</p>
                    </div>
                </Col>
                <Col md={7} style={{textAlign: "justify"}}>
                    <ol style={getStyle(2)} start={2}>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                Next you can specify a domain of your protein that will be targeted for docking to reduce on-target off-site binders. Starting from a residue that is known to be important for the biological function of your target protein you can define the size and orientation of a gridbox around your protein domain of interest. When defining the Gridbox for docking, you should keep in mind that both a potential ligand and your target protein need to fit inside this space for successful evaluation of all relevant binding positions.
                            </p>
                        </li>
                    </ol>
                </Col>
            </Row>
            <Col md={12}>
                <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
            </Col>
            <Row>
                <Col md={7}  style={{textAlign: "justify"}}>
                    <ol start={3}>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                Now you can choose if finDr should search peptide ligands for your target protein by MIVS or MIEA. You are also free to combine the two approaches in a custom-tailored MIEA. To do this please perform MIVS first to identify a number of good binding peptides and save the sequences of the best binders. As a next step you can integrate these in a custom-tailored MIEA approach for further optimization. Start finDrs MIEA modality and upload the sequences obtained from MIVS into the custom initial population. Please keep in mind that MIEA depends on a certain degree of diversity in its initial population, so we suggest filling up at least ⅓ of the initial population with random peptides from the library. FinDr will do this automatically.
                            </p>
                        </li>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                Also on this site you can choose whether you want to perform a mirror-image virtual screening /evolutionary algorithm (yielding metabolically stable D-peptide ligands) or conventional virtual screening /evolutionary algorithm (yielding L-peptide ligands).
                            </p>
                        </li>
                    </ol>
                </Col>
                <Col md={5}>
                    <div className="ImgWithCaption">  
                        <img src={eaorvs} alt="Selection of MIEA or MIVS" style={{maxWidth: "100%"}}></img>
                        <p style={{paddingTop: "10px"}}>Figure 3: Selection of MIEA or MIVS.</p>
                    </div>
                </Col>
            </Row>
            <Col md={12}>
                <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
            </Col>
            <Row>
                <Col md={6} style={{textAlign: "justify"}}>
                    <h2>MIVS:</h2>
                    <div style={{textAlign: "center"}}>
                        <img src={vsparams} alt="VS Parameters" style={{maxWidth: "100%"}}></img>
                        <p style={{paddingTop: "10px"}}>Figure 4: MIVS Parameters.</p>
                    </div>
                    <ol start={5}>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                You can now determine the parameters for MIVS: The exhaustiveness specifies how extensive the search algorithm of AutoDock Vina will look for the ideal binding position of each ligand to your target protein for determining its binding affinity. With a low exhaustiveness you can prioritize speed, with a higher exhaustiveness the MIVS will be more accurate but less peptides will be evaluated. FinDr displays the number of peptides that can be docked with your current choices. These will be selected randomly from our helical 12-mer peptide library.
                            </p>
                        </li>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                You need to provide a valid E-mail address to receive an URL for checking the status and the results of your job. After a final check of the parameters you can submit your job!
                            </p>
                        </li>
                    </ol>
                </Col>
                <Col md={6} style={{textAlign: "justify"}}>
                    <h2>MIEA:</h2>
                    <div style={{textAlign: "center"}}>
                        <img src={eaparams} alt="MIEA Parameters" style={{maxWidth: "100%"}}></img>
                        <p style={{paddingTop: "10px"}}>Figure 5: MIEA Parameters.</p>
                    </div>
                    <ol start={7}>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                                You can now determine the parameters for MIEA:
                            </p>
                            <ol style={{listStyleType: "lower-alpha"}}>
                                <li>
                                    <p className="bp3-running-text bp3-text-large">
                                        Firstly, you can choose the size and composition of the initial population, the starting point of MIEA. You can either choose to have an initial population made entirely from random peptides from our library or you can upload custom peptide sequences. (For example peptide sequences that you obtained in a prior MIVS for the same target). We recommend including at least a few randomly chosen peptides to ensure the necessary diversity of the initial population.
                                    </p>
                                </li>
                                <li>
                                    <p className="bp3-running-text bp3-text-large">
                                        The exhaustiveness specifies how extensive the search algorithm of AutoDock Vina will look for the ideal binding position of each ligand to your target protein for determining its binding affinity. With a low exhaustiveness you can prioritize speed, with a higher exhaustiveness the MIVS will be more accurate but less peptides will be evaluated.
                                    </p>
                                </li>
                                <li>
                                    <p className="bp3-running-text bp3-text-large">
                                        The copy rate, probability of mutation and elitism parameters determine how a new population of peptides will be generated based on the previously evaluated peptide population in each cycle of the MIEA. The copy rate determines which percentage of the best binding peptides of the previous generation should be copied into the next population. Conversely the rest of the population will be made up by recombination. The probability of mutation is the probability for the introduction of random single- amino acid mutations to the peptides. The number for the elitism parameter specifies how many peptides will be copied into the new population without any alteration.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p className="bp3-running-text bp3-text-large">
                            You need to provide a valid E-mail address to receive an URL for checking the status and the results of your job. After a final check of the parameters you can submit your job!
                            </p>
                        </li>
                    </ol>
                </Col>
                <Col md={12}>
                    <Callout intent="primary" icon="info-sign"> <i>finDr will perform MIVS or MIEA as soon as cores are available. You will receive an email with an URL where you can check the status of your job. When the job is completed you will also be notified. (By the way, finDr does not save your E-mail address for longer than the duration of your job. Also, you can only submit one job at a time.) </i> </Callout>
                </Col>
            </Row>
            <Col md={12}>
                <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
            </Col>
            <Row>
                <Col md={12} style={{textAlign: "justify"}}>
                    <ol start={9}>
                        <li>
                            <p className="bp3-running-text bp3-text-large">Checking and interpreting your results:</p>
                            <ol style={{listStyleType: "lower-alpha"}}>
                                <li style={{paddingBottom: "10px"}}>
                                    <p className="bp3-running-text bp3-text-large">
                                        <b>MIVS</b>: finDr provides a table of the top 10 D-peptide sequences and their binding energy to your target protein. Also, a histogram of all peptides from the library that were docked will be shown. In this histogram you can check for very low binding energies (-2 to -3) which can result from inaccessibility of binding sites due to a too narrow gridbox. You can download all relevant data from the MIVS for further analysis, including structural files of the top 5 peptide-target protein complexes and the chosen gridbox (for example for visualization in Pymol) and a complete list of all docked peptides and their binding energies.
                                    </p>
                                    <SubmissionInfoExample setLoading={(e: any) => {}} toaster={props.toaster} id="0dff8bd4-81ba-4ed3-b661-5cdd8a91243e" />
                                    <p style={{paddingTop: "10px", textAlign: "center"}}>Figure 6: MIVS Results.</p>
                                </li>
                                <li style={{paddingBottom: "10px"}}>
                                    <p className="bp3-running-text bp3-text-large">
                                        <b>MIEA</b>: finDr provides a summary of the MIEA run in several graphs: The development of the average binding energy of all peptides in each generation, the binding energy of the best binding peptide in each generation and the development of the binding energy of the top 25% peptides with the best binding energy per population. In the last one you can see the effect of elitism, the copying of the best binding peptide of one generation to the next without alteration, hence maintaining its binding energy. You can download all relevant data from the MIEA for further analysis, including structural files of the top 5 peptide-target protein complexes and the chosen gridbox (for example for visualization in Pymol) and a complete list of all docked peptides and their binding energies in each generation.
                                    </p>
                                    <SubmissionInfoExample setLoading={(e: any) => {}} toaster={props.toaster} id="fce39077-26e8-4811-8b51-d8a5279c371d" />
                                    <p style={{paddingTop: "10px", textAlign: "center"}}>Figure 7: MIEA Results.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Col>
            </Row>
            <Col md={12}>
                <div style={{height: "1px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.15)", marginTop: "5px"}}></div>
            </Col>
        </Container>
    )
}
/*
export default function Example(props : {toaster : IToaster, setLoading: Function}) {
    const location = useLocation();
    const [id, setId] = React.useState<string>("9f40c14e-5147-40db-a578-2716788d08d1")
    const [ea, setEA] = React.useState<boolean | undefined>()
    const [prevs, setPrevs] = React.useState<boolean | undefined>()
    const [noGens, setNoGens] = React.useState<number | undefined>();
    const [popSize, setPopSize] = React.useState<number | undefined>();
    const [status, setStatus] = React.useState<number>(-1)

    useEffect(() => {
        findJob(id);
    }, []); // empty deps => run once


    function findJob(ids: string = id) {
        props.setLoading(true);
        axios.get("/jobinfo/status", {
            params: {
                id: ids,
            }
        }).then((response) => {
            console.log(response.data)
            if (response.data !== false) {
                setId(ids);
                setStatus(response.data.status)
                props.setLoading(false);
            } else {
                props.toaster.show({message: "Error when displaying example. Please contact administrator.", intent: "danger", icon: "cross"})
                setStatus(-2);
                props.setLoading(false);
            }
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst looking for job: " + error + ". Please contact the administrator.", intent: "danger", icon: "cross"});
            }
        )
    }

    return (
        <Container className="SubmissionInfo Example">
            <h1>MIEA Example: 5GKR</h1>
            {status !== -1 && status !== -2
            ?
                <SubmissionParams toaster={props.toaster} id={id} ea={ea} setEA={setEA} setPrevs={setPrevs} prevs={prevs} noGens={noGens} setNoGens={setNoGens} popSize={popSize} setPopSize={setPopSize}></SubmissionParams>
            :
                null
            }
            {status === 1 || status === 2 || status === 3
            ?
                <SubmissionResult toaster={props.toaster} id={id} setLoading={props.setLoading} ea={ea} prevs={prevs} noGens={noGens} popSize={popSize}></SubmissionResult>
            :
                null
            }
        </Container>
    )
}
*/