import { AnchorButton, Card } from "@blueprintjs/core";
import React from "react";
import { Container, Col, Row } from "react-grid-system";
import axios from "axios";

export default function Tools() {
    return (
        <Container className="Tools">
            <Row>
                <Col xl={6}>
                <h1>Library</h1>
                    <Card>    
                        <p className="bp3-running-text bp3-text-large">Contains 1.458.278 helical 12-mer peptide segments in <i>.pdb</i> format.</p>
                        <p className="bp3-running-text bp3-text-large">For details, see paper Section 2.5.</p>
                        <div className="align-left-right">
                            <div>   
                            </div>
                            <AnchorButton target="_blank" href={axios.defaults.baseURL + "/tools/lib/12"} rel="noopener noreferrer" intent="primary" id="downloadButton">
                                Download
                            </AnchorButton>
                        </div>
                    </Card> 
                </Col>
                <Col xl={6}>
                    <h1>L to D Converter</h1>
                    <Card>    
                        <p className="bp3-running-text bp3-text-large">Python script to mirror protein structures (L to D/D to L). This is achieved by negating the x-coordinate of all atoms.</p>
                        <p className="bp3-running-text bp3-text-large"><b>Usage:</b></p>
                        <ul>
                            <li> <p className="bp3-running-text bp3-text-large"> To convert a single file: </p>
                                <pre className="Code"><span className="Shell">finDr@GLaDOS:<span className="Dir">~/Downloads</span><span className="Bang">$</span> </span>python3 LtoD.py infile.pdb outfile.pdb</pre>
                            </li>
                            <li> <p className="bp3-running-text bp3-text-large"> To convert all files contained in a folder: </p>
                                <pre className="Code"><span className="Shell">finDr@HAL9000:<span className="Dir">~/Downloads</span><span className="Bang">$</span> </span>python3 LtoD.py infolder outfolder</pre>
                            </li>
                        </ul>
                        <div className="align-left-right">
                            <div>
                            </div>
                            <AnchorButton target="_blank" href={axios.defaults.baseURL + "/tools/ltod"} rel="noopener noreferrer" intent="primary" id="downloadButton">
                                Download
                            </AnchorButton>
                        </div>
                    </Card> 
                </Col>
            </Row>
        </Container>
    )
}