import React, { useEffect } from 'react';
import axios from 'axios';
import { IToaster} from '@blueprintjs/core';
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-grid-system";
import SubmissionResult from "./SubmissionResult";
import SubmissionFind from "./SubmissionFind";
import SubmissionStatus from "./SubmissionStatus";
import SubmissionParams from "./SubmissionParams";
import qs from 'qs';

function SubmissionInfoExample(props: {setLoading: Function, toaster: IToaster, id: string}) {
    const [ea, setEA] = React.useState<boolean | undefined>()
    const [prevs, setPrevs] = React.useState<boolean | undefined>()
    const [noGens, setNoGens] = React.useState<number | undefined>();
    const [popSize, setPopSize] = React.useState<number | undefined>();
    const [status, setStatus] = React.useState<number>(-1)

    useEffect(() => {
        findJob(props.id);
    }, []); // empty deps => run once


    function findJob(ids: string = props.id) {
        props.setLoading(true);
        axios.get("/jobinfo/status", {
            params: {
                id: ids,
            }
        }).then((response) => {
            console.log(response.data)
            if (response.data !== false) {
                setStatus(response.data.status)
                props.setLoading(false);
            } else {
                setStatus(-2);
                props.setLoading(false);
            }
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst looking for job: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }

    return (
        <Container className="SubmissionInfo">
            <div style={{display: "none"}}>
            {status !== -1
            ?
                <SubmissionStatus status={status} id={props.id}></SubmissionStatus>
            :
                null
            }
            {status !== -1 && status !== -2
            ?
                <SubmissionParams toaster={props.toaster} id={props.id} ea={ea} setEA={setEA} setPrevs={setPrevs} prevs={prevs} noGens={noGens} setNoGens={setNoGens} popSize={popSize} setPopSize={setPopSize}></SubmissionParams>
            :
                null
            }
            </div>
            {status === 1 || status === 2 || status === 3
            ?
                <SubmissionResult toaster={props.toaster} id={props.id} setLoading={props.setLoading} ea={ea} prevs={prevs} noGens={noGens} popSize={popSize}></SubmissionResult>
            :
                null
            }
            {status === -2 ? 
                <p>Could not load data...</p>
                : null
            }
        </Container>
    )
}

function SubmissionInfo(props: {setLoading: Function, toaster: IToaster}) {
    const history = useHistory();
    const location = useLocation();
    const [id, setId] = React.useState<string>("")
    const [ea, setEA] = React.useState<boolean | undefined>()
    const [prevs, setPrevs] = React.useState<boolean | undefined>()
    const [noGens, setNoGens] = React.useState<number | undefined>();
    const [popSize, setPopSize] = React.useState<number | undefined>();
    const [status, setStatus] = React.useState<number>(-1)

    useEffect(() => {
        var queryid = qs.parse(location.search, {ignoreQueryPrefix: true})
        if (queryid.id != null) {
            setId(queryid.id.toString())
            findJob(queryid.id.toString());
        }
    }, []); // empty deps => run once


    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setId(event.target.value);
        setStatus(-1);
        props.setLoading(false);
    }

    function findJob(ids: string = id) {
        history.push("/info?id=" + ids);
        props.setLoading(true);
        axios.get("/jobinfo/status", {
            params: {
                id: ids,
            }
        }).then((response) => {
            console.log(response.data)
            if (response.data !== false) {
                setId(ids);
                setStatus(response.data.status)
                props.setLoading(false);
            } else {
                setStatus(-2);
                props.setLoading(false);
            }
        }).catch(
            (error) => {
                props.toaster.show({message: "Error whilst looking for job: " + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
    }

    return (
        <Container className="SubmissionInfo">
            <SubmissionFind id={id} onInputChange={onInputChange} onButtonClick={findJob}></SubmissionFind>
            {status !== -1
            ?
                <SubmissionStatus status={status} id={id}></SubmissionStatus>
            :
                null
            }
            {status !== -1 && status !== -2
            ?
                <SubmissionParams toaster={props.toaster} id={id} ea={ea} setEA={setEA} setPrevs={setPrevs} prevs={prevs} noGens={noGens} setNoGens={setNoGens} popSize={popSize} setPopSize={setPopSize}></SubmissionParams>
            :
                null
            }
            {status === 1 || status === 2 || status === 3
            ?
                <SubmissionResult toaster={props.toaster} id={id} setLoading={props.setLoading} ea={ea} prevs={prevs} noGens={noGens} popSize={popSize}></SubmissionResult>
            :
                null
            }
        </Container>
    )
};

export {
    SubmissionInfoExample,
    SubmissionInfo
}