import React from 'react';
import axios from "axios";
import { AnchorButton, Alert, Callout} from "@blueprintjs/core";

export default function SubmissionResult(props : {status: number, id: string}) {
    function getStatusJSX() {
        switch (props.status) {
            case -2:
                return <Callout intent="warning">Could not find submission.</Callout>
            case 0:
                return <Callout intent="primary">Job is in queue.</Callout>
            case 1:
                return <Callout intent="primary">Computation for job has started. Check back for intermediate results.</Callout>
            case 2:
                return <Callout intent="success" className="SubmissionResult">
                    Computation has finished without errors.
                        <AnchorButton target="_blank" href={axios.defaults.baseURL + "/jobinfo/download/?id=" + props.id} rel="noopener noreferrer" intent="success" id="downloadButton">Download data</AnchorButton>
                    </Callout>
                    
            case 3:
                return <Callout intent="warning">Process crashed, trying again.</Callout>
            case 4:
                return <Callout intent="danger">Job failed. Please contact administrator: findr@biologie.uni-freiburg.de for more information.</Callout>
            case 5:
                return <Callout intent="danger">Job failed. Please contact administrator: findr@biologie.uni-freiburg.de for more information.</Callout>
        }
    }
    return <div>
        {getStatusJSX()}
    </div>
}
