import React, { RefCallback, useCallback, useEffect } from 'react';
import axios from 'axios';
import { FileInput, AnchorButton, FormGroup, Slider, H3, Alert, IToaster, Switch, Card, Callout, MenuItem, Button, ProgressBar, Spinner, InputGroup, RadioGroup, Radio, TextArea, Dialog, Checkbox } from '@blueprintjs/core';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-grid-system';
import { Select, ItemRenderer } from "@blueprintjs/select";
import dummypdb from "./DummyPDB";

// import * as ngl from "ngl/dist/ngl.js";
const ngl: any = require("ngl/dist/ngl")

const timelimit = 16 * 60
const t_em = 0.1
const t_dock_fun = (exhaustiveness: number) => exhaustiveness * 0.1625
// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
// const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;  problem with chinese chars
const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const AA = "GALMFWKQESPVICYHRNDT";
const AAs = ['CYS', 'ASP', 'SER', 'GLN', 'LYS',
             'ILE', 'PRO', 'THR', 'PHE', 'ASN',
             'GLY', 'HIS', 'LEU', 'ARG', 'TRP',
             'ALA', 'VAL', 'GLU', 'TYR', 'MET']

interface IResidue {
    name: string,
    chain: string,
    pos: number,
    key: number
}

interface IRNADNAFeedback {
    [key: string]: string
}

interface IPDBFeedback {
    rnadna: IRNADNAFeedback,
    fixer: {
        missing: {
            chain: string,
            residue: string,
            missing: string[]
        }[],
        nonstandard: {
            chain: string,
            id: string,
            name: string,
            repl: string
        }[]
    }
}

const renderResidue: ItemRenderer<IResidue> = (residue, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${residue.pos} ${residue.chain}: ${residue.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={residue.key}
            onClick={handleClick}
            text={text}/>
    )
}

function calculateRuntime(n: number, g: number, r: number, exhaustiveness: number, prevs: boolean) : number {
    const t_dock = t_dock_fun(exhaustiveness);
    var result: number = r * t_em + n * g * (t_em + r * t_dock);
    if (prevs) {
        result += n * g * (t_em + r * t_dock);
    }
    return result;
}

function calculateLigandsVS(r: number, exhaustiveness: number) : number {
    const t_dock = t_dock_fun(exhaustiveness);
    // const calcRuntime = (x: number) => r * t_em + x * (t_em + r * t_dock)
    var result: number = Math.floor((timelimit - r * t_em) / (t_em + r * t_dock))
    return result;
}

/* Shown at start of submission process, where receptors are chosen */
function UploadReceptors(props : {setLoading: Function, toaster: IToaster, setStep: Function, setId: Function,
                                  setResidues: Function,
                                  selectedFiles?: FileList, setSelectedFiles: Function,
                                  setPDBfb: Function}) {
    const [fileFormName, setFileFormName] = React.useState<string>("Select files")
    const [formData, setFormData] = React.useState<FormData>(new FormData());
    // const noReceptors = props.selectedFiles != null ? props.selectedFiles.length : 0;

    function onFileChange(event : React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files != null) {
            props.setSelectedFiles(event.target.files);
            let names = ""
            for (var i = 0; i < event.target.files.length; i++) {
                names += event.target.files[i].name + ", "
            }
            setFileFormName(names.substr(0, names.length - 2));
        }
    }

    function sendToApi() {
        props.setLoading(true);
        // Send file to API
        axios.post("/submitjob/files", formData).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        props.toaster.show({message: "" + response.data.msg, intent: "success", icon:"tree"});
                        props.setId(response.data.id)
                        props.setPDBfb(response.data.pdbfb)
                        props.setResidues(response.data.residues)
                        props.setStep(1);
                    } else {
                        props.toaster.show({message: "" + response.data.msg, intent: "danger"})
                    }
                }
                props.setLoading(false);
            }
        ).catch(
            (error) => {
                setFormData(new FormData());
                if (error.response) {
                    if (error.response.status === 409) {
                        console.log(error.response)
                        props.toaster.show({message: "Upload failed. Check file extension", intent: "danger", icon: "cross"});
                    } else {
                        props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"})
                    }
                } else {
                    props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                }
                props.setLoading(false);
            }
        )
    }

    function onNextClick() {
        if (props.selectedFiles != null) {
            for (var i = 0; i < props.selectedFiles.length; i++) {
                formData.append("file", props.selectedFiles[i], props.selectedFiles[i].name)
            }
            sendToApi();
        }
    }

    return (
        <div>
            <Callout intent="primary" icon="info-sign" title="Choosing your target">
                <p>Please choose your target protein!</p>
            </Callout>
            <Callout intent="warning" icon="warning-sign" title="Non-protein compounds">
                <p>finDr does not currently support the simulation of non-protein compounds. FinDr will remove all RNA and DNA molecules before starting computation. More detail will be shown in the next step.</p>
            </Callout>
            <FormGroup label="Select target protein file in .pdb or .ent format.">
                <FileInput text={fileFormName} hasSelection={!(props.selectedFiles == null)}
                        onInputChange={onFileChange} inputProps={{multiple: false}}
                        id="file-upload" fill/>
            </FormGroup>
            <div className="align-right">
                <AnchorButton onClick={onNextClick} intent="primary" disabled={!(props.selectedFiles != null)}>Next</AnchorButton>
            </div>
        </div>
    )
}

function ChooseInitialPop(props: { toaster: IToaster, popSize: number, setPreVS: Function, initPop: string[], setInitPop: Function}) {
    const [mode, setMode] = React.useState<string>("random");
    const [initPopField, setInitPopField] = React.useState<string>("");

    useEffect(() => {
        if (props.popSize < props.initPop.length) {
            props.toaster.show({message: `Too many sequences for initial population! Removing the last ${props.initPop.length - props.popSize} sequence(s).`, intent: "danger", icon: "cross"});
            var newInitPop = [...props.initPop];
            newInitPop = newInitPop.slice(0, props.popSize - props.initPop.length);
            props.setInitPop(newInitPop);
        }
    }, [props.popSize])

    function checkValidFASTA(FASTA: string): boolean {
        for (var i = 0; i < FASTA.length; i++) {
            if (!AA.includes(FASTA.charAt(i))) {
                props.toaster.show({message: "Sequence contains invalid symbol, maybe non-standard AA?", intent: "danger", icon: "cross"})
                return false;
            }
        }
        /*
        for (var i = 0; i < initPop.length; i++) {
            if (FASTA.length != initPop[i].length) {
                props.toaster.show({message: "Sequence length differs from others. All sequences must have the same length.", intent: "danger", icon: "cross"})
                return false;
            }
        }
        */
        if (FASTA.length !== 12) {
            props.toaster.show({message: "Sequence must be 12 characters long.", intent: "danger", icon: "cross"})
            return false;
        }
        return true;
    }

    function addInitPop() {
        if (props.initPop.length === props.popSize) {
            props.toaster.show({message: "Too many sequences for initial population.", intent: "danger", icon: "cross"})
        } else {
            if (checkValidFASTA(initPopField)) {
                var newInitPop = [...props.initPop];
                newInitPop.push(initPopField);
                props.setInitPop(newInitPop);
                setInitPopField("");
            }
        }
    }

    function rmInitPop(idx: number) {
        var newInitPop = [...props.initPop];
        newInitPop.splice(idx, 1)
        props.setInitPop(newInitPop);
    }

    function onRadioChange(event: React.FormEvent<HTMLInputElement>) {
        if (event.currentTarget.value !== "seq" && mode === "seq") {
            props.setInitPop([]);
        }
        setMode(event.currentTarget.value);
    }

    return (
    <div>
        <RadioGroup
            onChange={onRadioChange}
            selectedValue={mode}>

            <Radio label="Peptides from 12-mer library (randomly chosen)" value="random" />
            <Radio label="Manual choice of initial population (upload of peptide sequences)..." value="seq" />
        </RadioGroup>
        {mode !== "seq"
        ?
            null
        :
            <div>
                <ul className="SequenceList">
                    {props.initPop.map((value, idx) => {
                        return <li key={idx}><AnchorButton intent="danger" icon="cross" onClick={() => rmInitPop(idx)} minimal></AnchorButton> {value}</li>
                    })}
                </ul>
                <div className="align-left-right">
                    <InputGroup value={initPopField.toLocaleUpperCase()} onChange={(event) => setInitPopField(event.target.value.toLocaleUpperCase())}>
                    </InputGroup>
                    <AnchorButton onClick={addInitPop} intent="success">Add</AnchorButton>
                </div>
                <Callout intent={"primary"}>
                    <p>Please enter peptide sequences in 1-letter code, without seperation. </p>
                    <p>You have provided {props.initPop.length} sequences. To fill up the population, {props.popSize - props.initPop.length} peptides will be chosen at random from 12-mer library.</p>
                </Callout>
                <Callout intent={"warning"} icon="info-sign">Please be aware that in some cases discrepancies in binding energies may arise between dockings in MIVS and MIEA for the same peptide sequence. This is most likely to occur for helical peptides of the library that were extracted from bent helices of the PDB database which can assume a different shape in MIEA protocol where a predominantly straight helical model is used. It is recommended to use bigger simulation box sizes to avoid any issues.</Callout>
            </div>
        }
    </div>
    )
}

function PDBValidation(props: { toaster: IToaster, id: string, setStep: Function, fb: IPDBFeedback }) {
    function onNextClick() {
        props.setStep(2);
    };

    return (
        <div>
            <p>
            finDr has prepared your PDB file for simulation using <a rel="noopener noreferrer" target="_blank" href="https://github.com/openmm/pdbfixer">PDBFixer</a>. If necessary, some of the following steps were performed:
            </p>
            <ul>
                <li>RNA/DNA molecules were removed</li>
                <li>Missing residues were inserted (except at the end/beginning of a chain)</li>
                <li>Non-standard residues were replaced with their canonical equivalent</li>
                <li>Heterogens were removed</li>
                <li>Missing atoms within residues were inserted</li>
                <li>Rotamers were deleted</li>
            </ul>
            {Object.keys(props.fb.rnadna).length !== 0
            ?
                <Card style={{maxHeight: "200px", overflow: "scroll"}}>
                    <p>The following RNA/DNA molecules were deleted:</p>
                    <ul>
                        {Object.keys(props.fb.rnadna).map((keyName, idx) => (
                                <li key={idx}>{keyName}: {props.fb?.rnadna[keyName]}</li>))
                        }
                    </ul>
                </Card>
            : null
            }
            {props.fb.fixer.nonstandard.length !== 0
            ?
                <Card style={{maxHeight: "200px", overflow: "scroll"}}>
                    <p>The following non-standard amino acids were replaced:</p>
                    <ul>
                        {props.fb.fixer.nonstandard.map((value, idx) => (
                            <li key={idx}>In chain <b>{value.chain}</b>, residue <b>{value.id}</b>: {value.name} --&gt; {value.repl}</li>
                        ))
                        }
                    </ul>
                </Card>
            : null
            }
            {props.fb.fixer.missing.length !== 0
            ?
                <Card style={{maxHeight: "200px", overflow: "scroll"}}>
                    <p>The following missing amino acids were inserted:</p>
                    <ul>
                        {props.fb.fixer.missing.map((value, idx) => (
                            <li key={idx}>In chain <b>{value.chain}</b> after residue <b>{value.residue}</b>:
                                <ul>
                                    {value.missing.map((value, idx) => (
                                        <li key={idx}>{value}</li>
                                    ))}
                                </ul>
                            </li>
                        ))
                        }
                    </ul>
                </Card>
            : null
            }
            <p style={{paddingTop: "10px"}}>We encourage you to <a rel="noopener noreferrer" target="_blank" href={axios.defaults.baseURL + "/submitjob/download/?id=" + props.id}>download</a> the fixed file for validation, before moving on to the next step.</p>
            <div className="align-left-right" style={{paddingTop: "10px"}}>
                <AnchorButton rel="noopener noreferrer" target="_blank" href={axios.defaults.baseURL + "/submitjob/download/?id=" + props.id} intent="primary">Download Fixed PDB</AnchorButton>
                <AnchorButton onClick={onNextClick} intent="primary">Next</AnchorButton>
            </div>
        </div>
    )
    /*
    props.setResidues(response.data.residues.map((value: {name: string, chain: string, pos: number}, idx: number) => {
                            return {
                                name: value.name,
                                chain: value.chain,
                                pos: value.pos,
                                key: idx
                            }
                        }))
      <p>Accept? {props.fb.accept ? "yes" : "no"}</p>
    <p>Fix1? {props.fb.fixes.fix1 ? "yes" : "no"}</p>
    <p>Fix2? {props.fb.fixes.fix2 ? "yes" : "no"}</p>
    <p>Fix3? {props.fb.fixes.fix3 ? "yes" : "no"}</p>
    */
}


const ResidueSelect = Select.ofType<IResidue>();

function ChooseResiduePDB(props: {setLoading: Function, toaster: IToaster, id: string, setStep: Function,
                                  residues: IResidue[]}) {
    const [residue, setResidue] = React.useState<IResidue>({key: -1, chain:"-1", pos: -1, name: "Select residue..."});
    const [distance, setDistance] = React.useState<number>(0);
    const [size, setSize] = React.useState<number>(30);
    const [offsetX, setOffsetX] = React.useState<number>(0);
    const [offsetY, setOffsetY] = React.useState<number>(0);
    const [offsetZ, setOffsetZ] = React.useState<number>(0);
    const [firstChosen, setFirstChosen] = React.useState<boolean>(false);
    const [pdbLoaded, setPdbLoaded] = React.useState<boolean>(false);
    const [dummy, setDummy] = React.useState<boolean>(false);
    const [gridboxBlob, setGridboxBlob] = React.useState<Blob>();
    const [stage, setStage] = React.useState();
    const [gridboxCenter, setGridboxCenter] = React.useState({x: 0, y: 0, z: 0});

    const stageElementRef: RefCallback<HTMLElement> = useCallback((element) => {
        if (element) {
          const currentStage = new ngl.Stage(element, { backgroundColor: "white"});
          setStage(currentStage);
        }
      }, []);

    function downloadGridbox() {
        if (gridboxBlob !== undefined) {
            const url = window.URL.createObjectURL(gridboxBlob);
            var templink = document.createElement("a");
            templink.href = url;
            templink.setAttribute("download", "gridbox.pdb");
            templink.click();
        }
    }

    function updateImg(residuepos: number, chain: string, distance: number, size: number,
                       offsetx: number, offsety: number, offsetz: number, dummy: boolean) {
        setPdbLoaded(false);
        axios.get("/submitjob/showPDB", {
            params: {
                uuid: props.id
            },
            responseType: 'arraybuffer'
        }).then((response) => {
            const colorscheme = ngl.ColormakerRegistry.addSelectionScheme([
                ["red", `${residuepos}:${chain}`],
                ["green", "*"]
            ])
            const colorscheme2 = ngl.ColormakerRegistry.addSelectionScheme([
                ["blue", "*"]
            ])
            // @ts-ignore: Object is possibly 'null'.
            stage.removeAllComponents();
            console.log(response.data);
            const binaryBlobOriginal = new Blob([response.data], { type: 'chemical/x-pdb' })
            // @ts-ignore: Object is possibly 'null'.
            stage.loadFile(binaryBlobOriginal, {ext: "pdb"}).then((o) => {
                o.addRepresentation("cartoon", {color: colorscheme})
                o.autoView();
            });
            axios.get("/submitjob/showgridboxPDB",{
                params: {
                    uuid: props.id,
                    residue: residuepos,
                    chain: chain,
                    distance: distance,
                    size: size,
                    offsetx: offsetx,
                    offsety: offsety,
                    offsetz: offsetz
                },
                responseType: 'arraybuffer'
            }).then(
                (response) => {;
                    props.setLoading(false);
                    console.log(response.data);
                    const binaryBlobGridbox = new Blob([response.data], { type: 'chemical/x-pdb' })
                    setGridboxBlob(binaryBlobGridbox);
                    // @ts-ignore: Object is possibly 'null'.
                    stage.loadFile(binaryBlobGridbox, {defaultRepresentation: true, ext: "pdb"}).then((o) => {
                        setGridboxCenter(o.structure.center)
                    });
                    /* Dummy PDB */
                    if (dummy) {
                        // @ts-ignore: Object is possibly 'null'.
                        stage.loadFile(dummypdb, {ext: "pdb"}).then((o) => {
                            o.setPosition(gridboxCenter);
                            o.addRepresentation("cartoon", {color: colorscheme2});
                        });
                    }
                    setPdbLoaded(true);
                }
            ).catch(
                (error) => {
                    props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                    props.setLoading(false);
                }
            )
        }).catch(
            (error) => {
                props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
                props.setLoading(false);
            }
        )
    }
    function onNextClick() {
        props.setStep(3);
    }

    function handleItemSelect(newResidue: IResidue) {
        if (newResidue.name === "GLY") {
            props.toaster.show({message: "Can't select Glycine", intent: "danger", icon: "cross"});
        } else {
            setFirstChosen(true);
            setResidue(newResidue);
            updateImg(newResidue.pos, newResidue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);
        }
    }

    function handleSizeRelease(size: number) {
        if (residue.pos !== -1) updateImg(residue.pos, residue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);
    }

    function handleOffsetXRelease(offsetX : number) {
        if (residue.pos !== -1) updateImg(residue.pos, residue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);
    }

    function handleOffsetYRelease(offsetY : number) {
        if (residue.pos !== -1) updateImg(residue.pos, residue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);
    }

    function handleOffsetZRelease(offsetZ : number) {
        if (residue.pos !== -1) updateImg(residue.pos, residue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);
    }

    function handleDummyChecked(dummy: boolean) {
        setDummy(dummy);
        if (residue.pos !== -1) updateImg(residue.pos, residue.chain, distance, size, offsetX, offsetY, offsetZ, dummy);

    }
    return (
        <div>
            <Callout intent="primary" icon="info-sign" title="Which domain of the target protein do you want to target for D-peptide ligand identification?">
                <p> In this step, you can choose a residue of special interest. We will generate a search space around this residue which will exclusively be used for docking. You can choose for example the active site of your protein or the residues that are involved in its interaction with other proteins. Alternatively you can define a larger gridbox encompassing the whole molecule. </p>
                <p> <b> You can rotate/zoom/move the 3D view with your mouse! </b> </p>
            </Callout>
            <div className="align-left-right SearchSpace">
                <div className="Image">
                    {!firstChosen
                    ?
                        <div className="center-text"><p>Selet a residue to get visual feedback</p></div>
                    : null}
                    {pdbLoaded || !firstChosen
                    ?
                        null
                    :
                        <Spinner intent="primary"></Spinner>
                    }
                    <div id="nglviewer" style={{width: "350px", height: "350px"}} ref={stageElementRef}></div>
                </div>

                <div>
                    <FormGroup label="Residue of interest">
                        <ResidueSelect
                            items={props.residues}
                            itemRenderer={renderResidue}
                            onItemSelect={handleItemSelect}
                            popoverProps={{ minimal: true }}
                            className={"ResidueSelect"}
                            filterable={false}>
                                <Button text={residue.pos !== -1 ? `${residue.pos} ${residue.chain}: ${residue.name}` : "Select residue..."}
                                        rightIcon="double-caret-vertical" />
                        </ResidueSelect>
                    </FormGroup>
                    <FormGroup label="Size of gridbox in Å:">
                        <Slider min={25} max={50} stepSize={1} labelStepSize={5} initialValue={30}
                            showTrackFill={false}
                            value={size}
                            onChange={(value) => setSize(value)}
                            onRelease={handleSizeRelease}/>
                    </FormGroup>
                    <FormGroup label="Gridbox center offset from Residue of interest in Å:">
                        <FormGroup label="X offset">
                            <Slider min={-25} max={25} stepSize={1} labelStepSize={5} initialValue={0}
                                showTrackFill={false}
                                value={offsetX}
                                onChange={(value) => setOffsetX(value)}
                                onRelease={handleOffsetXRelease}/>
                        </FormGroup>
                        <FormGroup label="Y offset">
                            <Slider min={-25} max={25} stepSize={1} labelStepSize={5} initialValue={0}
                                showTrackFill={false}
                                value={offsetY}
                                onChange={(value) => setOffsetY(value)}
                                onRelease={handleOffsetYRelease}/>
                        </FormGroup>
                        <FormGroup label="Z offset">
                            <Slider min={-25} max={25} stepSize={1} labelStepSize={5} initialValue={0}
                                showTrackFill={false}
                                value={offsetZ}
                                onChange={(value) => setOffsetZ(value)}
                                onRelease={handleOffsetZRelease}/>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox checked={dummy} onChange={(e) => handleDummyChecked(e.currentTarget.checked)}
                        label="Enable 12-mer Arginine dummy ligand (blue; not included in simulation)" />
                    </FormGroup>
                </div>
            </div>
            <Callout intent={"warning"} icon="info-sign">Please keep in mind that both a potential ligand and your target protein need to fit inside this space for successful evaluation of all relevant binding positions.</Callout>
            <div className="align-left-right">
                <AnchorButton onClick={downloadGridbox} intent="primary" disabled={gridboxBlob === undefined}>Download Gridbox</AnchorButton>
                <AnchorButton onClick={onNextClick} intent="primary" disabled={residue.key === -1}>Next</AnchorButton>
            </div>
        </div>
    )
}

function VSorEA(props: {setStep: Function, mirror: boolean, setMirror: Function}) {
    const switchLabel = props.mirror ? "Mirror-image" : "Conventional";

    function radioOnChange(event: React.FormEvent<HTMLInputElement>) {
        if (event.currentTarget.value === "Mirror-image") {
            props.setMirror(true);
        } else {
            props.setMirror(false);
        }
    }

    return (
        <div>
            <Callout intent="primary" icon="info-sign" title="Do you prefer a deterministic or heuristic search approach?">
                <p> Do you want to perform mirror-image virtual screening (MIVS) of a helical peptide libary or do you want to perform a mirror-image evolutionary algorithm (MIEA) to identify ligand to your target protein? </p>
                <p> You are also free to combine the two approaches in a custom-tailored MIEA. To do this please perform MIVS first to identify a number of good binding peptides and save the sequences of the best binders. As a next step you can integrate these in a custom-tailored MIEA approach for further optimization. Start finDrs MIEA modality and upload the sequences obtained from MIVS into the custom initial population. Please keep in mind that MIEA depends on a certain degree of diversity in its initial population, so we suggest filling up at least ⅓ of the initial population with random peptides from the library. FinDr will do this automatically.  </p>
                <p> Parameters can be specified in the next step. </p>
            </Callout>
            <RadioGroup label={"Are you looking for D-ligands or L-ligands?"} onChange={radioOnChange} selectedValue={switchLabel}>
                <Radio label="Find D-ligands – finDr will mirror your file." value="Mirror-image"></Radio>
                <Radio label="Find L-ligands – finDr will not mirror your file." value="Conventional"></Radio>
            </RadioGroup>
            <div className="align-left-right">
                <AnchorButton onClick={() => props.setStep(4)}>
                    Virtual Screening
                </AnchorButton>
                <AnchorButton onClick={() => props.setStep(5)}>
                    Evolutionary Algorithm
                </AnchorButton>
            </div>
        </div>
    )
}

function checkEmail(email: string) {
    return re.test(String(email).toLowerCase());
}

function ParamsSubmitVS(props: {toaster: IToaster, selectedFiles?: FileList, mirror: boolean, uuid: string}) {
    const history = useHistory();
    const [exhaustiveness, setExhaustiveness] = React.useState(2);
    const [dialogueOpen, setDialogueOpen] = React.useState(false);
    const receptors: number = (props.selectedFiles === undefined) ? 0 : props.selectedFiles?.length ;
    const ligandsAmount: number = calculateLigandsVS(receptors, exhaustiveness);
    const [email, setEmail] = React.useState<string>("");
    const dialogueDisabled = !checkEmail(email);
    
    function submitForm() {
        const formData = new FormData();

        formData.append("uuid", props.uuid);
        formData.append("ea", (false).toString());
        formData.append("exhaustiveness", exhaustiveness.toString());
        formData.append("mirror", props.mirror.toString());
        formData.append("email", email);

        // Send file to API
        axios.post("/submitjob", formData).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        props.toaster.show({message: "" + response.data.msg, intent: "success", icon:"tree"});
                        history.push("/success", {id: props.uuid});
                    } else {
                        props.toaster.show({message: "" + response.data.msg, intent: "danger"})
                        history.push("/fail", {reason: response.data.msg});
                    }
                }
            }
        ).catch(
            (error) => {
                props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
        
    }

    function onSubmitClick() {
        setDialogueOpen(true);
    }


    return(
        <div className="SubmissionSubmitForm">
            <Alert isOpen={dialogueOpen}
                confirmButtonText="I'm 100% sure!"
                cancelButtonText="Let me check again..."
                intent="warning"
                onCancel={() => setDialogueOpen(false)}
                onConfirm={() => { setDialogueOpen(false); submitForm()} }>
                <h2>Are you absolutely sure about these parameters?</h2>
                <p>
                    Once a job is submitted, it will be added to a queue and executed when
                    processors are available.
                    Since every job will take a considerable amount of computing power and time,
                    we encourage you to check all parameters again.
                </p>
            </Alert>
            <H3>VS Parameters</H3>
            <Row gutterWidth={0}>
                <FormGroup helperText="This parameter of AutoDock Vina specifies how extensive the search algorithm will look for the ideal binding position of a ligand to your target protein for determining its binding affinity."
                        label="Exhaustiveness">
                    <Slider min={1} max={4} stepSize={1} labelStepSize={1} initialValue={4}
                            showTrackFill={false}
                            value={exhaustiveness}
                            onChange={(value: number) => setExhaustiveness(value)}/>
                </FormGroup>
            </Row>
            <Row gutterWidth={0}>
                <FormGroup helperText="Please provide an E-mail address. We will notify you as soon as your job is completed!" label="E-Mail address">
                    <InputGroup value={email} onChange={(event) => setEmail(event.target.value)}>
                    </InputGroup>
                </FormGroup>
            </Row>
            
            <Row id="submitButtonRow">
                <Callout intent="primary" id="paramsWarning">
                    With these parameters, we can perform a virtual screening for {ligandsAmount} ligands.
                </Callout>
                {dialogueDisabled
                ?
                    <Callout intent="warning" id="paramsWarning">
                        Please enter a valid E-Mail address.
                    </Callout>
                :
                    null
                }
                <AnchorButton disabled={dialogueDisabled} intent={!dialogueDisabled ? "primary" : "none"} rightIcon="confirm" text="Submit" onClick={onSubmitClick}/>
            </Row>
        </div>
    )
}

function ParamsSubmit(props: {toaster: IToaster, selectedFiles?: FileList, mirror: boolean, uuid: string}) {
    const history = useHistory();
    const [noGens, setNoGens] = React.useState(30);
    const [popSize, setPopSize] = React.useState(30);
    const [probMut, setProbMut] = React.useState(0.3);
    const [copyRate, setCopyRate] = React.useState(0.3);
    const [elitism, setElitism] = React.useState(1);
    const [exhaustiveness, setExhaustiveness] = React.useState(2);
    const [preVS, setPreVS] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [initPop, setInitPop] = React.useState<string[]>([]);

    const [dialogueOpen, setDialogueOpen] = React.useState(false);
    const receptors: number = (props.selectedFiles === undefined) ? 0 : props.selectedFiles?.length ;
    const formInvalid = (calculateRuntime(popSize, noGens, receptors, exhaustiveness, preVS) < (timelimit) ? false : true);
    const emailInvalid = !checkEmail(email);

    
    function submitForm() {
        const formData = new FormData();

        formData.append("uuid", props.uuid);
        formData.append("ea", true.toString());
        formData.append("noGens", noGens.toString());
        formData.append("popSize", popSize.toString());
        formData.append("probMut", probMut.toFixed(3));
        formData.append("copyRate", copyRate.toFixed(3));
        formData.append("exhaustiveness", exhaustiveness.toString());
        formData.append("elitism", elitism.toString());
        formData.append("prevs", preVS.toString());
        formData.append("mirror", props.mirror.toString());
        formData.append("email", email);
        formData.append("initPop", JSON.stringify(initPop))

        // Send file to API
        axios.post("/submitjob", formData).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        props.toaster.show({message: "" + response.data.msg, intent: "success", icon:"tree"});
                        history.push("/success", {id: props.uuid});
                    } else {
                        props.toaster.show({message: "" + response.data.msg, intent: "danger"})
                    }
                }
            }
        ).catch(
            (error) => {
                props.toaster.show({message: "" + error + ". Please contact the administrator: findr@biologie.uni-freiburg.de.", intent: "danger", icon: "cross"});
            }
        )
        
    }

    function onSubmitClick() {
        setDialogueOpen(true);
    }

    /*
    <Col md={5}>
                    <AnchorButton className={[Classes.OUTLINED, Classes.MINIMAL].join(" ")} onClick={() => setOverlayOpen(true)}> <img src={GAIMG} alt="Principle of GA" style={{width: "75%"}}/> </AnchorButton>
                    <p>Click to enlarge...</p>
                </Col>
     <Overlay isOpen={overlayOpen} canEscapeKeyClose canOutsideClickClose onClose={() => setOverlayOpen(false)}>
                <div style={{width: "1000px", position: "absolute", left: "50%", top: "50%",
                             transform: "translate(-50%, -50%)", backgroundColor: "rgba(255, 255, 255, 0.8)",
                             borderRadius: "5px", padding: "10px" }}>
                    <img src={GAIMG} alt="Principle of GA" style={{width: "100%"}}/>
                </div>
            </Overlay>
    */

    return(
        <div className="SubmissionSubmitForm">
            <Alert isOpen={dialogueOpen}
                confirmButtonText="I'm 100% sure!"
                cancelButtonText="Let me check again..."
                intent="warning"
                onCancel={() => setDialogueOpen(false)}
                onConfirm={() => { setDialogueOpen(false); submitForm()} }>
                <h2>Are you absolutely sure about these parameters?</h2>
                <p>
                    Once a job is submitted, it will be added to a queue and executed when
                    processors are available.
                    Since every job will take a considerable amount of computing power and time,
                    we encourage you to check all parameters again.
                </p>
            </Alert>
            <Row>
                <Col md={6}>
                    <Row gutterWidth={0}>
                        <H3>EA Parameters</H3>
                    </Row>
                    <Row gutterWidth={0}>
                        <p><i>For a refresher on how the evolutionary principle works, click <a rel="noopener noreferrer" target="_blank" href="https://findr.biologie.uni-freiburg.de/principle#MIEA">here</a> (link opens in new tab). </i></p>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="How many iterations should the evolutionary algorithm perform?"
                                label="Number of generations">
                            <Slider min={1} max={80} stepSize={1} labelValues={[1, 10, 20, 30, 40, 50, 60, 70, 80]} initialValue={10}
                                    showTrackFill={false}
                                    value={noGens}
                                    onChange={(value: number) => setNoGens(value)}/>
                        </FormGroup>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="How many peptides should be in each population?"
                                label="Size of population">
                            <Slider min={1} max={80} stepSize={1} labelValues={[1, 10, 20, 30, 40, 50, 60, 70, 80]} initialValue={10}
                                    showTrackFill={false}
                                    value={popSize}
                                    onChange={(value: number) => { 
                                        setPopSize(value);
                                        if (elitism > value) {
                                            setElitism(value);
                                        }
                                    }}/>
                        </FormGroup>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="What percentage of the best binding peptides of the previous generation should be copied into the next population? Conversely, the rest of the population will be made up by recombination of the best binding peptides."
                                label="Copy rate">
                            <Slider min={0} max={1} stepSize={0.05} labelStepSize={0.1} initialValue={0.3}
                                    showTrackFill={false}
                                    value={copyRate}
                                    onChange={(value: number) => setCopyRate(value)}/>
                        </FormGroup>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="With which probability should random single-amino acid mutations be introduced to peptides in the process of assembling new peptide populations?"
                                label="Probability of mutation">
                            <Slider min={0} max={1} stepSize={0.05} labelStepSize={0.1} initialValue={0.3}
                                    showTrackFill={false}
                                    value={probMut}
                                    onChange={(value: number) => setProbMut(value)}/>
                        </FormGroup>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="How many of the best binding peptides in each generation should be copied into the next population without any alteration (neither recombination nor mutation)?"
                                label="Elitism">
                            <Slider min={0} max={popSize} stepSize={1} labelStepSize={5} initialValue={4}
                                    showTrackFill={false}
                                    value={elitism}
                                    onChange={(value: number) => setElitism(value)}/>
                        </FormGroup>
                    </Row>
                    <Row gutterWidth={0}>
                        <FormGroup helperText="This parameter of AutoDock Vina specifies how extensive the search algorithm will look for the ideal binding position of a ligand to your target protein for determining its binding affinity. "
                                label="Exhaustiveness">
                            <Slider min={1} max={4} stepSize={1} labelStepSize={1} initialValue={4}
                                    showTrackFill={false}
                                    value={exhaustiveness}
                                    onChange={(value: number) => setExhaustiveness(value)}/>
                        </FormGroup>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row gutterWidth={0}>
                        <H3>Initial population</H3>
                    </Row>
                    <Row gutterWidth={0}>
                        <ChooseInitialPop toaster={props.toaster} popSize={popSize} setPreVS={setPreVS} initPop={initPop} setInitPop={setInitPop}/>
                    </Row>
                </Col>
                <Col md={12}>
                    <Row gutterWidth={0}>
                        <FormGroup label="E-Mail address" helperText="Please provide an E-mail address. We will notify you as soon as your job is completed!">
                            <InputGroup value={email} onChange={(event) => setEmail(event.target.value)}>
                            </InputGroup>
                        </FormGroup>
                    </Row>
                    <Row id="submitButtonRow">
                        {formInvalid
                        ?
                            <Callout intent="warning" id="paramsWarning">
                                Your submission would take too long. Please reduce some parameters.
                            </Callout>
                        :
                            null
                        }
                        {emailInvalid
                        ?
                            <Callout intent="warning" id="paramsWarning">
                                Please enter a valid E-Mail address.
                            </Callout>
                        :
                            null
                        }
                        <AnchorButton rightIcon="confirm" intent={(!formInvalid && !emailInvalid) ? "primary" : "none"} text="Submit" onClick={onSubmitClick} disabled={formInvalid || emailInvalid}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default function SubmissionSubmit(props : {setLoading: Function, toaster: IToaster}) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0);
    // Parameters that occur in different steps
    const [id, setId] = React.useState<string>("");
    const [mirror, setMirror] = React.useState<boolean>(true);
    const [fileList, setFileList] = React.useState<FileList>();
    const [residues, setResidues] = React.useState<IResidue[]>([]);
    const [PDBfb, setPDBfb] = React.useState<IPDBFeedback>({ rnadna: {}, fixer: {missing: [], nonstandard: []} });

    function getSubComponent() {
        switch (step) {
            case 0:
                return <UploadReceptors setLoading={setLoading} toaster={props.toaster} setStep={setStep} setId={setId}
                                            setResidues={setResidues} selectedFiles={fileList} setSelectedFiles={setFileList}
                                            setPDBfb={setPDBfb} />
            case 1:
                return <PDBValidation toaster={props.toaster} id={id} setStep={setStep} fb={PDBfb}/>
            case 2:
                return <ChooseResiduePDB setLoading={setLoading} toaster={props.toaster} id={id} setStep={setStep} residues={residues}
                                    />
            case 3:
                return <VSorEA setStep={setStep} mirror={mirror} setMirror={setMirror}/>
            case 4:
                return <ParamsSubmitVS selectedFiles={fileList} mirror={mirror} toaster={props.toaster}
                                        uuid={id}/>
            case 5:
                return <ParamsSubmit selectedFiles={fileList} mirror={mirror} toaster={props.toaster}
                                    uuid={id}/>
            default:
                return <div></div>;
        }
    }
    return(
        <div>
            <ProgressBar stripes={false} intent="primary"
                         value={step < 3 ? step/4 : 0.75}/>
            <Row className="SubmissionSubmit">
                <Col xs={12}>
                    <p>
                        
                    </p>
                </Col>
                <Col className="SubComponent" xs={12}>
                    <Card elevation={1}>
                        {!loading
                        ?
                            getSubComponent()
                        :
                            <div className="LoadingPDB">
                                <Spinner>  
                                </Spinner>
                                <div style={{padding: "0px", textAlign:"justify"}}>finDr is preparing the .pdb/.ent file of your target protein for docking. In this process, molecules that are not needed for the simulation, such as waters of crystallization and RNA/DNA are removed,
                                 missing residues are inserted, non-standard residues are converted to their canonical equivalent, and more. Depending on the size of the protein and the current server workload <b>this can take several minutes</b>. Please be patient! </div>
                            </div>
                        }

                    </Card>
                </Col>
            </Row>
        </div>
    )
}
